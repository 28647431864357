import { ClockedPayType } from '../enums/timesheet.enum';
import { IPayrollType } from '../interfaces/payroll-type.interface';

export class PayrollType implements IPayrollType {
    payType: ClockedPayType;
    payrollItem: string;    // QuickBook item
    qbListId: string;       // QuickBook time ID

    constructor(payType: ClockedPayType, payrollItem: string, qbListId: string) {
        this.payType = payType;
        this.payrollItem = payrollItem;
        this.qbListId = qbListId;
    }
}
