import { AuthService } from './../../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/classes/user.class';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public pushRightClass: string;
  public user$: Observable<User>;
  public userName: string;

  constructor(public router: Router, private authService: AuthService) {
    this.router.events.subscribe(val => {
        if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
            this.toggleSidebar();
        }
    });
}

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.user$ = this.authService.user$.pipe(
      tap(user => {
        this.userName = user.firstName + ' ' + user.lastName;
      }));
  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  logout() {
    this.authService.logoutUser();
  }




}
