<h1 mat-dialog-title>Create Yellowsheet</h1>
<div mat-dialog-content>
    <mat-form-field>
        <input matInput [matDatepicker]="picker" formControl="yellowsheetDate" placeholder="Please choose a date" [formControl]="yellowsheetDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

</div>
<div mat-dialog-actions>
    <button mat-button mat-dialog-close mat-raised-button color="secondary">Cancel</button>
    <button mat-button [mat-dialog-close]="yellowsheetDate" cdkFocusInitial mat-raised-button color="primary">Create</button>
</div>
