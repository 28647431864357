import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PayrollItem } from 'src/app/models/classes/payroll-item.class';
import { Timesheet } from 'src/app/models/classes/timesheet.class';
import { ClockedHourType, ClockedPayType, TimesheetStatus } from 'src/app/models/enums/timesheet.enum';
import { QBTimeData } from 'src/app/models/classes/quickbook-timedata.class';
import { ClockedType } from 'src/app/models/classes/clocked-type.class';
import { PayrollType } from 'src/app/models/classes/payroll-type.class';
import { TimesheetService } from 'src/app/services/timesheets/timesheet.service';
import { FirestoreService } from '../firestore/firestore.service';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { IUser } from 'src/app/models/interfaces/user.interface';
import { PayrollItemStatus } from 'src/app/models/enums/payrollitems.enum';
import { IJob } from 'src/app/models/interfaces/job.interface';
import { IService } from 'src/app/models/interfaces/service.interface';

@Injectable({
    providedIn: 'root'
})
export class PayrollService {

    constructor(
        public db: FirestoreService,
        private timesheetService: TimesheetService,
    ) {
    }

    getPayrollItems(fromDate: string, toDate: string): Observable<PayrollItem[]> {
          return this.db.colWithIds$('payrollitems', ref => ref.where('clockIn', '>=', new Date(fromDate))
                .where('clockIn', '<=', new Date(toDate)).orderBy('clockIn', 'asc'));
    }

    getPayrollItemsByUserId(fromDate: string, toDate: string, qbListId: string): Observable<PayrollItem[]> {
        return this.db.colWithIds$('payrollitems', ref => ref.where('clockIn', '>=', new Date(fromDate))
                .where('user.qbListId', '==', qbListId)
                .where('clockIn', '<=', new Date(toDate)).orderBy('clockIn', 'asc'));
  }

  getPayrollItemsByTimesheetId(timesheetId: string): Observable<PayrollItem[]> {
        if (timesheetId !== undefined) {
            return this.db.colWithIds$('payrollitems', ref => ref.where('timesheetId', '==', timesheetId)
                    .orderBy('clockIn', 'asc'));
        }
    }

    getPayrollItemsByTimesheetIdAndUserId(timesheetId: string, qbListId: string): Observable<PayrollItem[]> {
        if (timesheetId !== undefined) {
            return this.db.colWithIds$('payrollitems', ref => ref.where('timesheetId', '==', timesheetId)
                                                                 .where('user.qbListId', '==', qbListId)
                                                                 .orderBy('clockIn', 'asc'));
        }
    }

    getPayrollItem(payrollItemId: string) {
        return this.db.doc$('payrollitems/' + payrollItemId);
    }

    async updatePayrollItem(payrollItem: PayrollItem): Promise<void> {
        if (payrollItem.id === undefined) {
          payrollItem.id = this.db.createId();
        }
        return await this.db.upsert('payrollitems/' + payrollItem.id, payrollItem);
    }

    async deletePayrolltime(payrollItemId: string): Promise<void> {
        return await this.db.delete('payrollitems/' + payrollItemId);
    }

    public approvePayrollItemsForUser(userId: string, fromDate: string, toDate: string) {
      const sub = this.db.colWithIds$('payrollitems', ref => ref.where('clockIn', '>=', new Date(fromDate))
        .where('user.userId', '==', userId)
        .where('clockIn', '<=', new Date(toDate))
        .orderBy('clockIn', 'asc')).subscribe(async (payrollItems: PayrollItem[]) => {
            sub.unsubscribe();

            for (const payrollItem of payrollItems) {
                // Only process billable time
                if (payrollItem.clockedType.hourType !== ClockedHourType.LUNCH_BREAK) {
                    payrollItem.status = PayrollItemStatus.APPROVED;
                }

                // return this.updatePayrollItem(payrollItem);
                await this.updatePayrollItem(payrollItem);
            }
        });
    }

    public createPayrollItemsForUser(qbListId: string, fromDate: string, toDate: string) {
        // Acquire the submitted timesheets associated with the user based upon the from/to dates.
        const sub1 = this.timesheetService.getTimesheetsByUser(qbListId, fromDate, toDate, TimesheetStatus.APPROVED).subscribe(approvedTimesheets => {
            sub1.unsubscribe();

            if (approvedTimesheets.length === 0) {
                const result = {
                    errorCode: 404,
                    message: `processTimeSheets: There are no submitted timesheets for userId: ${qbListId} from ${fromDate} to ${toDate}.`
                };

                console.log(`createPayrollItemsForUser: ${JSON.stringify(result)}`);
                return result;
            }

            // Acquire the processed timesheets associated with the user based upon the from/to dates.
            const sub2 = this.timesheetService.getTimesheetsByUser(qbListId, fromDate, toDate, TimesheetStatus.PROCESSED).subscribe(async processedTimesheets => {
                sub2.unsubscribe();

                // Get all the SplitTimes associated with the processed timesheets.
                const processedPayrollItems = await this.organizePayrollItems(processedTimesheets);

                let timesheets: Timesheet[];
                if (approvedTimesheets) {
                    // Combine the submittedTimesheets with the processedTimesheets so that we can process them together within the getDataGroupedByClockInDate.
                    timesheets = approvedTimesheets.concat(processedTimesheets);
                } else {
                    timesheets = processedTimesheets;
                }

                // Get the grouped timesheets ordered by time so that we can batch process timesheets based upon the day
                // because a work day can have multiple timesheets associated with it.
                const timeSheetsGroupedByDay = this.getGroupedTimesheetsOrderedByClockInDate(timesheets);

                // Process the acquired timesheets and splitTimes.
                this.processTimeSheets(timeSheetsGroupedByDay, processedPayrollItems);
            });
        });
    }

    private async organizePayrollItems(timesheets: Array<Timesheet>): Promise<Array<PayrollItem>> {
        const promises: Array<Promise<any>> = [];

        timesheets.forEach(timesheet => {
            const promise = new Promise((resolve, reject) => {
                const sub = this.getPayrollItemsByTimesheetId(timesheet.id).subscribe(results => {
                    sub.unsubscribe();

                    resolve(results);
                });
            });

            promises.push(promise);
        });

        const payrollItems: Array<PayrollItem> = [];

        await Promise.all(promises).then(observers => {
            observers.forEach(splitTimeData => {
                splitTimeData.forEach(splitTime => {
                  payrollItems.push(splitTime);
                });
            });
        }).catch(error => console.log(`organizePayrollItems: Error in promises ${error}`));

        // We must sort the splitTimes to ensure that we have them in the proper order.
        this.sortPayrollItems(payrollItems);

        return payrollItems;
    }

    private getGroupedTimesheetsOrderedByClockInDate(timesheets: Array<Timesheet>) {
      // Inline function to package the timesheet data into their specified groups based upon the key.
      const packageTimesheetData = (accumulator, currentValue) => {
          const clockInDate = moment(currentValue.clockIn.toDate());

          // The unique KEY will be the [YEAR]-[DAY OF THE YEAR]. This will allow us to return a group
          // list of timeSheet data in ascending order (handling days that span into a different year).
          const key = `${clockInDate.year()}-${clockInDate.dayOfYear()}`;

          // Ensure that the Timesheet processed flag is set to FALSE
          currentValue.processed = false;

          if (!accumulator[key]) {
              const dataArr = [];
              dataArr.push({
              timeSheet: currentValue
              });

              accumulator[key] = [{
                  data: dataArr
              }];
          } else {
              accumulator[key][0].data.push({
                  timeSheet: currentValue
              });
          }

          return accumulator;
      };

      // Package the timesheet data.
      const timesheetGroups = timesheets.reduce(packageTimesheetData, {});

      // Group and sort the timesheet data by the key into an array.
      const timesheetsArray = Object.keys(timesheetGroups).sort().map(key => timesheetGroups[key]);

      // Flatten the timesheet array into a list.
      const timesheetList = timesheetsArray.reduce((a, c) => {
        return a.concat(c);
      }, []);

      return timesheetList;
    }

    private async processTimeSheets(timeSheetsGroupedByDay, currentPayrollItems: Array<PayrollItem>) {
        const payrollItems: Array<PayrollItem> = [];
        const timesheets: Array<Timesheet> = [];

        let timesheetsTotalClockedHrs = 0;

        // Iterate through the TimeSheets that are grouped by the current day and ordered by time so that we can batch process the timesheets by the day.
        timeSheetsGroupedByDay.forEach(timeSheetGroupedByDay => {
            const processedTimesheets: Array<Timesheet> = [];
            const approvedTimesheets: Array<Timesheet> = [];

            // Acquire the daily timesheets
            timeSheetGroupedByDay.data.forEach(data => {
                const timesheet = data.timeSheet;
                timesheets.push(timesheet);

                if (data.timeSheet.status === TimesheetStatus.APPROVED) {
                    approvedTimesheets.push(timesheet);
                } else if (data.timeSheet.status === TimesheetStatus.PROCESSED)  {
                    processedTimesheets.push(timesheet);
                }
            });

            // Acquire any splitTimes that are associated with the processedTimesheets.
            const processedPayrollItems = this.getPayrollItemsFromTimesheets(processedTimesheets, currentPayrollItems);

            // If we have existing splitTimes then we must reset the payType and update the Total Clocked Hours used for the OVERTIME calculation.
            processedPayrollItems.forEach(splitTime => {
                // We MUST reset the payType to REGULAR_TIME because we will process these previous splitTimes with
                // the newly generated splitTimes (from the submitted timesheets) which could change the payType.
                splitTime.payrollType.payType = ClockedPayType.REGULAR_TIME;

                const startDateTime = moment(splitTime.clockIn.toDate());
                const endDateTime = moment(splitTime.clockOut.toDate());

                const timeDuration = moment.duration(endDateTime.diff(startDateTime));
                timesheetsTotalClockedHrs += timeDuration.asHours();
            });

            const result = this.splitTimes(approvedTimesheets, processedPayrollItems, timesheetsTotalClockedHrs);

            // Update the timesheetsTotalClockedHrs and payrollItems with the result of the processLunchBreak.
            timesheetsTotalClockedHrs = result.timesheetsTotalClockedHrs;
            result.splitTimes.forEach(splitTime => {
                payrollItems.push(splitTime);
            });
        });

        // Determine if we have payrollItems
        if (payrollItems.length > 0) {
            if (timesheetsTotalClockedHrs > 40) {
                // console.log(`processTimeSheets: payrollItems before processOverTime ${JSON.stringify(payrollItems)}`);
                this.processOverTime(payrollItems, timesheetsTotalClockedHrs);
                // console.log(`processTimeSheets: payrollItems after processOverTime ${JSON.stringify(payrollItems)}`);
            }

            // Update the Timesheets status to be "Processed".
            for (const timesheet of timesheets) {
                if (timesheet.status !== TimesheetStatus.PROCESSED) {
                    timesheet.status = TimesheetStatus.PROCESSED;

                    // update our timesheet record to be TimesheetStatus.PROCESSED.
                    await this.timesheetService.updateTimesheet(timesheet).then(result => {
                        // console.log(`updateTimesheet: Successfully updated timesheet ${timesheet.id}`);
                    })
                    .catch(error => {
                        console.log(`!!! ERROR: updateTimesheet failed to update the timesheet: ${error}`);
                    });
                }
            }

            // Save the SPLIT-TIME records
            for (const payrollItem of payrollItems) {
                // Stringify the clockedType and payrollType objects and then parse to object again to remove class names of the object.
                //
                // This will prevent the following error from occurring:
                //
                // FirebaseError: [code=invalid-argument]: Function DocumentReference.set() called with invalid data. Unsupported field value: a custom ClockedType object (found in field clockedType).
                payrollItem.clockedType = JSON.parse(JSON.stringify(payrollItem.clockedType));
                payrollItem.payrollType = JSON.parse(JSON.stringify(payrollItem.payrollType));

                // update our timesheet record to be TimesheetStatus.PROCESSED
                await this.updatePayrollItem(payrollItem)
                .then(result => {
                    // console.log(`updatePayrollItem: Successfully added the payrollItem ${JSON.stringify(splitTime)}`);
                })
                .catch(error => {
                    console.log(`!!! ERROR: updatePayrollItem failed to add the payrollItem: ${error}`);
                });
            }
        }
    }

    private getPayrollItemsFromTimesheets(timesheets: Array<Timesheet>, currentPayrollItems: Array<PayrollItem>): Array<PayrollItem> {
        const payrollItems: Array<PayrollItem> = [];

        if (currentPayrollItems.length > 0) {
            timesheets.forEach(timesheet => {
                const results = currentPayrollItems.filter(x => x.timesheetId === timesheet.id);
                results.forEach(splitTime => {
                    payrollItems.push(splitTime);
                });
            });
        }

        return payrollItems;
    }

    public splitTimes(timesheets: Array<Timesheet>, processedSplitTimes: Array<PayrollItem>, timesheetsTotalClockedHrs: number): any {
        const splitTimes: Array<PayrollItem> = [];

        // The rules associated with adding the 1/2 Lunch Break:
        //
        // 1. The timesheet record associated with this day that is greater than 5 hours will be the candidate to be split for the
        //     Lunch Break
        // 2. If no timesheet record was found to be more than 5 hours for this day but the overall workday is greater than 5 hours then a
        //    Lunch Break will be added by splitting the timesheet that can accommodate a 1/2 lunch break and is closest to 12 noon

        let addedLunchBreak = false;   // initialize the added lunch break

        let closestToNoonTimeClockIn = 24; // 24 hours in a day

        let splitTimesheet: Timesheet = null;

        let foundSplitTimeSheet = false;

        // Initialize the current day total hours
        let currentDayTotalHrs = 0;

        if (processedSplitTimes.length > 0) {
            // Determine if there is an existing LUNCH BREAK within the processed splitTimes.
            const results = processedSplitTimes.filter(x => x.clockedType.hourType === ClockedHourType.LUNCH_BREAK);
            if (results.length > 0) {
                addedLunchBreak = true;
            }
        }

        // 1. The timesheet record associated with this day that is greater than 5 hours will be the candidate to be split for the
        //    Lunch Break
        //
        // IMPORTANT: We MUST go through this iteration, even if the addedLunchBreak is TRUE, because we need to calculate the
        //            Total Clocked Hours (e.g. timesheetsTotalClockedHrs) from all the timesheets.
        timesheets.forEach(timesheet => {
            const startDateTime = moment(timesheet.clockIn.toDate());
            const endDateTime = moment(timesheet.clockOut.toDate());

            // Acquire the timesheetsTotalClockedHrs to determine OVERTIME
            const timeDuration = moment.duration(endDateTime.diff(startDateTime));
            timesheetsTotalClockedHrs += timeDuration.asHours();

            // Acquire the total number of hours for this current day.
            currentDayTotalHrs += timeDuration.asHours();

            // If the current timesheet duration is greater than 5 hours and we have not added
            // a Lunch Break then attempt to find the time that is closest to 12 noon.
            if (!timesheet.noLunchBreak && timeDuration.asHours() > 5 && !addedLunchBreak) {
                if (this.doesTimesheetIncludeHour(timesheet, 12)) {
                    splitTimesheet = timesheet;
                    foundSplitTimeSheet = true;
                } else if (!foundSplitTimeSheet) {
                    const closestToNoonTime = this.closestTimeToHour(timesheet, 12);

                    // Now attempt to acquire the timesheet that is closes to 12 noon.
                    if (closestToNoonTime < closestToNoonTimeClockIn) {
                        closestToNoonTimeClockIn = closestToNoonTime;
                        splitTimesheet = timesheet;
                    }
                }
            }

            // Determine if we have a timesheet candidate to split for the 1/2 hour lunch break.
            if (!timesheet.noLunchBreak && splitTimesheet && !addedLunchBreak) {
                const splitHourValue = this.getSplitHourValue(splitTimesheet);

                const splitTimesheetHours = this.splitHoursForLunchBreak(splitTimesheet, splitHourValue, 0.5);

                if (splitTimesheetHours.length > 0) {
                    // Add the split timesheets into the timesheets
                    splitTimesheetHours.forEach(splitTimesheetHour => {
                        splitTimes.push(splitTimesheetHour);
                    });

                    addedLunchBreak = true;
                    timesheet.processed = true;
                }
            }
        });

        // 2. If we did not add a Lunch Break (meaning that no timesheet record was found to be more than 5 hours for this day)
        //    but the overall workday is greater than 5 hours then a Lunch Break will be added by splitting the timesheet
        //    that can accomodate a 1/2 lunch break and is closest to 12 noon.
        //
        // IMPORTANT: We MUST process all timesheets because we if we find a timesheet that was NOT processed within Step #1
        //            then we must split the timesheet.
        timesheets.forEach(timesheet => {
            // If we did not add the Lunch Break then we will pick the timesheet that can be split to incorporate a
            // 1/2 hour lunch break and is closest to 12 noon.
            if (!timesheet.noLunchBreak && !addedLunchBreak && currentDayTotalHrs > 5) {
                closestToNoonTimeClockIn = 24; // 24 hours in a day

                foundSplitTimeSheet = false;

                const startDateTime = moment(timesheet.clockIn.toDate());
                const endDateTime = moment(timesheet.clockOut.toDate());

                const timeDuration = moment.duration(endDateTime.diff(startDateTime));

                // Determine if this timesheet can be split to include a 1/2 hour lunch break.
                if (timeDuration.asHours() > 0.5) {
                    if (this.doesTimesheetIncludeHour(timesheet, 12)) {
                        splitTimesheet = timesheet;
                        foundSplitTimeSheet = true;
                    } else if (!foundSplitTimeSheet) {
                        const closestToNoonTime = this.closestTimeToHour(timesheet, 12);

                        // Now attempt to acquire the timesheet that is closes to 12 noon.
                        if (closestToNoonTime < closestToNoonTimeClockIn) {
                            closestToNoonTimeClockIn = closestToNoonTime;
                            splitTimesheet = timesheet;
                        }
                    }
                }

                // Determine if we found a timesheet that we can split for the 1/2 hour Lunch Break.
                if (splitTimesheet && !addedLunchBreak) {
                    const splitHourValue = this.getSplitHourValue(splitTimesheet);

                    const splitTimesheetHours = this.splitHoursForLunchBreak(splitTimesheet, splitHourValue, 0.5);
                    // console.log(`processLunchBreak: splitTimesheetHours is ${JSON.stringify(splitTimesheetHours)}`);

                    if (splitTimesheetHours.length > 0) {
                        splitTimesheetHours.forEach(splitTimesheetHour => {
                            splitTimes.push(splitTimesheetHour);
                        });

                        addedLunchBreak = true;
                        timesheet.processed = true;
                    }
                }
            }

            // If this timesheet was NOT processed then we must add the timesheet to the splitTimes.
            if (!timesheet.processed) {
                const splitTime = this.getSplitTimeFromTimesheet(timesheet);

                splitTimes.push(splitTime);

                timesheet.processed = true;
            }
        });

        // Determine if we have processed splitTimes (e.g. splitTimes that are associated with processed timesheets).
        if (processedSplitTimes.length > 0) {
            processedSplitTimes.forEach(existingSplitTime => {
                splitTimes.push(existingSplitTime);
            });
        }

        // Sort the splitTimes so that they are properly ordered
        this.sortPayrollItems(splitTimes);

        return {splitTimes, timesheetsTotalClockedHrs};
    }

    public processOverTime(splitTimes, timesheetsTotalClockedHrs: number) {
        // The rules associated with adding OVERTIME:
        //
        // 1. Workers do not get paid for lunch breaks so we must deduct the lunch break time from the timesheetsTotalClockedHrs
        // 2. When the timesheetsTotalClockedHrs exceeds 40 hours then the timesheet must be split to accomodate for the regular
        //    time versus overtime, when applicable

        // Workers do not get paid for lunch breaks so we must subtract out the lunch break time from the timesheetsTotalClockedHrs
        splitTimes.forEach(splitTime => {
            if (splitTime.clockedType && splitTime.clockedType.hourType && splitTime.clockedType.hourType === ClockedHourType.LUNCH_BREAK) {
                timesheetsTotalClockedHrs -= 0.5;
            }
        });

        // Only add in the OT (OverTime) hours if the total clocked hours exceeds 40 hours
        //
        // IMPORTANT: Only perform this operation after we have checked for the lunch break because if there is a need to add
        //            a lunch break we do subtract out the 1/2 lunch break because the workers do not get paid for lunch breaks.
        if (timesheetsTotalClockedHrs > 40) {
            const overTimeHrs = timesheetsTotalClockedHrs - 40;

            this.updateSplitTimesForOvertimeHours(splitTimes, overTimeHrs);
            // console.log(`processOverTime: splitTimes after updateSplitTimesForOvertimeHours ${JSON.stringify(splitTimes)}`);
        }
    }

    private updateSplitTimesForOvertimeHours(splitTimes: Array<PayrollItem>, overTimeHrs: number) {
        // let splitForOverTime = false;

        let splitTimesTotalClockedHrs = 0;

        // Ensure that the splitTimes are ordered by clockIn time. This is crucial to have since we are determining
        // when a splitTime should be split again and marked as splitTimes as OVERTIME.
        this.sortPayrollItems(splitTimes);
        // console.log(`updateSplitTimesForOvertimeHours: sorted splitTimes ${JSON.stringify(splitTimes)}`);

        const tempSplitTimes: Array<PayrollItem> = [];

        splitTimes.forEach(splitTime => {
            let updatedSplitTime = false;

            // Skip the lunch break PayrollItem record
            if (splitTime.clockedType.hourType !== ClockedHourType.LUNCH_BREAK) {
                const clockIn = splitTime.clockIn.toDate();
                const clockOut = splitTime.clockOut.toDate();

                const splitTimeClockIn = moment(clockIn);
                const splitTimeClockOut = moment(clockOut);


                const timeDuration = moment.duration(splitTimeClockOut.diff(splitTimeClockIn));

                // Calculate the remaining regular time in HRS to be used for the split
                const remainingRegularTimeInHours = 40 - splitTimesTotalClockedHrs;

                // Calculate the split time total clocked hours
                splitTimesTotalClockedHrs += timeDuration.asHours();

                // Determine if this splitTime exceeds the 40 hours
                if (splitTimesTotalClockedHrs > 40 && splitTime.payrollType.payType === ClockedPayType.REGULAR_TIME) {
                    if (remainingRegularTimeInHours > 0) {
                        // We must split this payrollItem to accommodate for the OverTime.
                        const newSplitTimes: Array<PayrollItem> = this.splitHoursForOverTime(splitTime, remainingRegularTimeInHours, overTimeHrs);

                        newSplitTimes.forEach(newSplitTime => {
                            tempSplitTimes.push(newSplitTime);
                        });
                        // console.log(`updateSplitTimesForOvertimeHours: tempSplitTimes ${JSON.stringify(tempSplitTimes)}`);

                        // Set the splitForOverTime to now be TRUE so that any other splitTime, within this iteration, will be marked as OVERTIME.
                        // splitForOverTime = true;
                    } else {
                        // we must mark this splitTime as OVERTIME
                        splitTime.payrollType.payType = ClockedPayType.OVERTIME;
                        tempSplitTimes.push(splitTime);
                    }

                    // Set the updatedSplitTime so that we DO NOT copy the current splitTime into the tempSplitTimes.
                    updatedSplitTime = true;
                }
            }

            if (!updatedSplitTime) {
                // if (splitForOverTime) {
                //     // If we have set the splitForOverTime then we must mark this splitTime as OVERTIME
                //     splitTime.payrollType.payType = ClockedPayType.OVERTIME;
                // }

                tempSplitTimes.push(splitTime);
            }
        });

        // Clear out the current splitTimes.
        //
        // IMPORTANT: list = [] assigns a reference to a new array to a variable, while any other references are unaffected.
        //            Which means that references to the contents of the previous array are still kept in memory, leading to memory leaks.
        //            list.length = 0 deletes everything in the array, which does hit other references.
        splitTimes.length = 0;

        // Assign the splitTimes to the values of the tempSplitTimes.
        tempSplitTimes.forEach(tempSplitTime => {
            splitTimes.push(tempSplitTime);
        });

        // console.log(`updateSplitTimesForOvertimeHours: splitTimes before sorting ${JSON.stringify(splitTimes)}`);
        this.sortPayrollItems(splitTimes);
        // console.log(`updateSplitTimesForOvertimeHours: splitTimes after sorting ${JSON.stringify(splitTimes)}`);
    }

    private splitHoursForLunchBreak(timesheet: Timesheet, splitHourValue: number, lunchBreakTime: number): Array <PayrollItem> {
        const splitTimes: Array<PayrollItem> = [];

        const qbTimeData = this.mapTimesheetQuickBookDataIntoPayrollType(timesheet);

        const clockInMS: number = timesheet.clockIn.toMillis();
        const clockOutMS: number = timesheet.clockOut.toMillis();

        let clockedHours: PayrollItem;

        let dtEndMS = clockInMS + this.hrsToMS(splitHourValue);

        // Determine if we were provided with a splitHourValue to allot for the BEFORE LUNCH timesheet.
        if (splitHourValue > 0) {
            // Add BEFORE_LUNCH time using the split hour value to determine when the lunch hour begins.
            clockedHours = this.cloneData(undefined,
                                            clockInMS,
                                            dtEndMS,
                                            timesheet.id,
                                            timesheet.user,
                                            timesheet.job,
                                            timesheet.selectedService,
                                            ClockedHourType.BEFORE_LUNCH,
                                            ClockedPayType.REGULAR_TIME,
                                            qbTimeData.regularTimeId,
                                            qbTimeData.regularTimeName);
            splitTimes.push(clockedHours);
        }

        // Add the Lunch Break timesheet.
        let dtStartMS = dtEndMS;
        dtEndMS = dtEndMS + this.hrsToMS(lunchBreakTime);
        clockedHours = this.cloneData(undefined,
                                        dtStartMS,
                                        dtEndMS,
                                        timesheet.id,
                                        timesheet.user,
                                        timesheet.job,
                                        timesheet.selectedService,
                                        ClockedHourType.LUNCH_BREAK,
                                        ClockedPayType.REGULAR_TIME,
                                        qbTimeData.regularTimeId,
                                        qbTimeData.regularTimeName);
        splitTimes.push(clockedHours);

        // Determine if we can add an AFTER LUNCH timesheet.
        if (clockOutMS > dtEndMS) {
            dtStartMS = dtEndMS;
            clockedHours = this.cloneData(undefined,
                                            dtStartMS,
                                            clockOutMS,
                                            timesheet.id,
                                            timesheet.user,
                                            timesheet.job,
                                            timesheet.selectedService,
                                            ClockedHourType.AFTER_LUNCH,
                                            ClockedPayType.REGULAR_TIME,
                                            qbTimeData.regularTimeId,
                                            qbTimeData.regularTimeName);
            splitTimes.push(clockedHours);
        }

        return splitTimes;
    }

    private getSplitHourValue(timesheet: Timesheet): number {
        const clockInDateTime = moment(timesheet.clockIn.toDate());
        const clockOutDateTime = moment(timesheet.clockOut.toDate());

        const diffClockInFromNoonTime = Math.abs(clockInDateTime.hour() - 12);
        const diffClockOutFromNoonTime = Math.abs(clockOutDateTime.hour() - 12);

        let splitHourValue = 0;

        // Determine which the clocked time (e.g. ClockIn or ClockOut) is closest to 12 noon (e.g. LUNCH TIME).
        if (diffClockInFromNoonTime <= diffClockOutFromNoonTime) {
            // Now determine how many hours we need to add to the ClockIn time to split the time and ensure that we have a Lunch Break.
            if (clockInDateTime.hour() < 12) {
                splitHourValue = clockInDateTime.hour() - 12;
            } else {
                splitHourValue = 0.5;
            }
        } else {
            // Now determine how many hours we need to add to the ClockIn time to split the time and ensure that we have a Lunch Break.
            if (clockOutDateTime.hour() < 12) {
                splitHourValue = clockOutDateTime.hour() - clockInDateTime.hour() - 1;
            } else {
                splitHourValue = clockInDateTime.hour() - 12;
            }
        }

        return Math.abs(splitHourValue);
    }

    private closestTimeToHour(timesheet: Timesheet, hour: number): number {
        const clockInDateTime = moment(timesheet.clockIn.toDate());
        const clockOutDateTime = moment(timesheet.clockOut.toDate());

        const diffClockInFromNoonTime = Math.abs(clockInDateTime.hour() - hour);
        const diffClockOutFromNoonTime = Math.abs(clockOutDateTime.hour() - hour);

        // Determine which clocked time (e.g. ClockIn or ClockOut) is closest to the hour.
        if (diffClockInFromNoonTime <= diffClockOutFromNoonTime) {
            return diffClockInFromNoonTime;
        } else {
            return diffClockOutFromNoonTime;
        }
    }

    private doesTimesheetIncludeHour(timesheet: Timesheet, hour: number): boolean {
        const clockInDateTime = moment(timesheet.clockIn.toDate());
        const clockOutDateTime = moment(timesheet.clockOut.toDate());

        return hour > clockInDateTime.hour() && hour < clockOutDateTime.hour();
    }

    private getSplitTimeFromTimesheet(timesheet: Timesheet): PayrollItem {
        const clockInMS: number = timesheet.clockIn.toMillis();
        const clockOutMS: number = timesheet.clockOut.toMillis();

        const qbTimeData = this.mapTimesheetQuickBookDataIntoPayrollType(timesheet);

        const clockInDateTime = moment(clockInMS);

        const clockHourType = clockInDateTime.hour() < 12 ? ClockedHourType.BEFORE_LUNCH : ClockedHourType.AFTER_LUNCH;

        return this.cloneData(undefined,
                                clockInMS,
                                clockOutMS,
                                timesheet.id,
                                timesheet.user,
                                timesheet.job,
                                timesheet.selectedService,
                                clockHourType,
                                ClockedPayType.REGULAR_TIME,
                                qbTimeData.regularTimeId,
                                qbTimeData.regularTimeName);
    }

    private mapTimesheetQuickBookDataIntoPayrollType(timesheet: Timesheet): QBTimeData {
        const qbTimeData = new QBTimeData(
            timesheet.user.regularTime.name,
            timesheet.user.regularTime.qbListId,
            timesheet.user.overTime.name,
            timesheet.user.overTime.qbListId
        );

        return qbTimeData;
    }

    private splitHoursForOverTime(splitTime: PayrollItem, remainingRegularTimeInHours: number, overTimeHrs: number): PayrollItem[] {
        const splitTimes: Array<PayrollItem> = [];

        // console.log(`splitHoursForOverTime: splitTime = ${JSON.stringify(splitTime)}`);

        // IMPORTANT: The splitTime clockIn/clockOut are stored as a firebase.firestore.Timestamp object so we must
        //            map them to a moment date and then acquire the milliseconds value since the clone operation
        //            needs them to be in milliseconds.
        const clockIn = splitTime.clockIn.toDate();
        const clockOut = splitTime.clockOut.toDate();
        const clockInMS: number = moment(clockIn).valueOf();
        const clockOutMS: number = moment(clockOut).valueOf();

        let clockedHours: PayrollItem;

        const dtEndMS = clockInMS + this.hrsToMS(remainingRegularTimeInHours);
        // const dtEndMS = clockInMS + this.hrsToMS(overTimeHrs);

        const remainingRegularTimeInHoursMS = this.hrsToMS(remainingRegularTimeInHours);
        // const splitTheTime: boolean = clockOutMS > dtEndMS;

        // Only add another splitTime if the clockOutMS exceeds the dtEndMS, otherwise the splitTime is the OVERTIME.
        if (remainingRegularTimeInHoursMS > 0) {
        // if (splitTheTime) {
            // Create a cloned PayrollItem.
            clockedHours = this.cloneData(undefined,
                                            clockInMS,
                                            dtEndMS,
                                            splitTime.timesheetId,
                                            splitTime.user,
                                            splitTime.job,
                                            splitTime.selectedService,
                                            splitTime.clockedType.hourType,
                                            splitTime.payrollType.payType,
                                            splitTime.payrollType.qbListId,
                                            splitTime.payrollType.payrollItem);
            splitTimes.push(clockedHours);

            // Create a cloned PayrollItem as OVERTIME.
            clockedHours = this.cloneData(undefined,
                                            dtEndMS,
                                            clockOutMS,
                                            splitTime.timesheetId,
                                            splitTime.user,
                                            splitTime.job,
                                            splitTime.selectedService,
                                            splitTime.clockedType.hourType,
                                            ClockedPayType.OVERTIME,
                                            splitTime.payrollType.qbListId,
                                            splitTime.payrollType.payrollItem);
            splitTimes.push(clockedHours);
        } else {
            // Create a cloned PayrollItem as OVERTIME.
            //
            // NOTE: This clone will retain the previous splitTime ID value
            clockedHours = this.cloneData(splitTime.id,
                                            clockInMS,
                                            clockOutMS,
                                            splitTime.timesheetId,
                                            splitTime.user,
                                            splitTime.job,
                                            splitTime.selectedService,
                                            splitTime.clockedType.hourType,
                                            ClockedPayType.OVERTIME,
                                            splitTime.payrollType.qbListId,
                                            splitTime.payrollType.payrollItem);
            splitTimes.push(clockedHours);
        }

        // console.log(`splitHoursForOverTime: splitTimes = ${JSON.stringify(splitTimes)}`);

        return splitTimes;
    }

    private cloneData(id: string,
                      clockInMS: number,
                      clockOutMS: number,
                      timesheetId: string,
                      user: IUser,
                      job: IJob,
                      selectedService: IService,
                      clockedHourType: ClockedHourType,
                      payType: ClockedPayType,
                      qbTimeId: string,
                      qbTimeName: string): PayrollItem {
        return new PayrollItem(id,
                            timesheetId,
                            user,
                            job,
                            selectedService,
                            firebase.firestore.Timestamp.fromMillis(clockInMS),
                            firebase.firestore.Timestamp.fromMillis(clockOutMS),
                            new ClockedType(clockedHourType),
                            new PayrollType(payType, qbTimeName, qbTimeId),
                            0);
    }

    private sortPayrollItems(splitTimes: Array<PayrollItem>) {
        if (splitTimes.length > 1) {
            // Sort the SplitTimes based upon the clockIn date.
            //
            // IMPORTANT: The splitTime clockIn is stored as a firebase.firestore.Timestamp object so we must map
            //            it to a moment date and then acquire the milliseconds value for the sort comparison.
            splitTimes = splitTimes.sort((splitTime1, splitTime2) => {
                const splitTime1Date = splitTime1.clockIn.toDate();
                const splitTime2Date = splitTime2.clockIn.toDate();

                // Compare the milliseconds
                return moment(splitTime1Date).valueOf() - moment(splitTime2Date).valueOf();
            });
        }
    }

    private hrsToMS(hours: number): number {
        return hours * 3600000;
    }
}
