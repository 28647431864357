import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentSnapshotDoesNotExist,
  DocumentSnapshotExists,
  Action,
  DocumentChangeAction
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import {
  map,
  tap,
  take
} from 'rxjs/operators';

type CollectionPredicate<T> = string | AngularFirestoreCollection<T>;
type DocPredicate<T> = string | AngularFirestoreDocument<T>;

@Injectable()
export class FirestoreService {
  constructor(private db: AngularFirestore) {}

  /// **************
  /// Get a Reference
  /// **************
  public col<T>(
    ref: CollectionPredicate<T>,
    queryFn?
  ): AngularFirestoreCollection<T> {
    return typeof ref === 'string' ? this.db.collection<T>(ref, queryFn) : ref;
  }

  public doc<T>(ref: DocPredicate<T>): AngularFirestoreDocument<T> {
    return typeof ref === 'string' ? this.db.doc<T>(ref) : ref;
  }

  public createId(): string {
    return this.db.createId();
  }

  /// **************
  /// Get Data
  /// **************
  public doc$<T>(ref: DocPredicate<T>): Observable<T> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map(doc => {
          return doc.payload.data() as T;
        })
      );
  }

  /// example: this.db.col$('users', ref => ref.where('user', '==', 'Jeff'))
  public col$<T>(ref: CollectionPredicate<T>, queryFn?): Observable<T[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map(docs => {
          return docs.map(a => a.payload.doc.data()) as T[];
        })
      );
  }

  /// with Ids
  public colWithIds$<T>(
    ref: CollectionPredicate<T>,
    queryFn?
  ): Observable<any[]> {
    return this.col(ref, queryFn)
      .snapshotChanges()
      .pipe(
        map((actions: DocumentChangeAction<T>[]) => {
          return actions.map((a: DocumentChangeAction<T>) => {
            const data = a.payload.doc.data() as T;
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        })
      );
  }

  /// with Ids
  public docWithIds$<T>(ref: DocPredicate<T>): Observable<any> {
    return this.doc(ref)
      .snapshotChanges()
      .pipe(
        map(a => {
          const data = a.payload.data();
          const id = a.payload.id;
          return { id, ...data };
        })
      );
  }

  /// **************
  /// Write Data
  /// **************
  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  /// db.update('items/ID', data) }) // adds updatedAt field
  /// db.set('items/ID', data) })    // adds createdAt field
  /// db.add('items', data) })       // adds createdAt field
  /// db.delete(('items/ID')

  set<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const timestamp = this.timestamp;
    return this.doc(ref).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  update<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    return this.doc(ref).update({
      ...data,
      updatedAt: this.timestamp
    });
  }

  delete<T>(ref: DocPredicate<T>): Promise<void> {
    return this.doc(ref).delete();
  }

  add<T>(
    ref: CollectionPredicate<T>,
    data
  ): Promise<firebase.firestore.DocumentReference> {
    const timestamp = this.timestamp;
    return this.col(ref).add({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  geopoint(lat: number, lng: number) {
    return new firebase.firestore.GeoPoint(lat, lng);
  }

  /// If doc exists update, otherwise set
  /// this.db.upsert('notes/xyz', { content: 'hello dude'})
  async upsert<T>(ref: DocPredicate<T>, data: any): Promise<void> {
    const doc = this.doc(ref)
      .snapshotChanges()
      .pipe(take(1))
      .toPromise();

    const snap = await doc;
    return snap.payload.exists
      ? this.update(ref, data)
      : this.set(ref, data);
  }

  async upsertSubcollectionDoc<T>(subCollection: string, docId: string, data: any): Promise<void> {
    return docId
      ? this.updateSubcollectionDoc(subCollection, docId, data)
      : this.setSubcollectionDoc(subCollection, data);
  }

  setSubcollectionDoc<T>(subCollection: string, data: any): Promise<void> {
    const timestamp = this.timestamp;
    const docId = this.createId();
    return this.doc(subCollection + docId).set({
      ...data,
      updatedAt: timestamp,
      createdAt: timestamp
    });
  }

  updateSubcollectionDoc<T>(subCollection: string, docId: string, data: any): Promise<void> {
    return this.doc(subCollection + docId).update({
      ...data,
      updatedAt: this.timestamp
    });
  }

  /// **************
  /// Inspect Data
  /// this.db.inspectDoc('notes/xyz')
  /// this.db.inspectCol('notes')
  /// **************
  inspectDoc(ref: DocPredicate<any>): void {
    const tick = new Date().getTime();
    this.doc(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap(
          (
            d: Action<
              DocumentSnapshotDoesNotExist | DocumentSnapshotExists<any>
            >
          ) => {
            const tock = new Date().getTime() - tick;
            console.log(`Loaded Document in ${tock}ms`, d);
          }
        )
      )
      .subscribe();
  }

  inspectCol(ref: CollectionPredicate<any>): void {
    const tick = new Date().getTime();
    this.col(ref)
      .snapshotChanges()
      .pipe(
        take(1),
        tap((c: DocumentChangeAction<any>[]) => {
          const tock = new Date().getTime() - tick;
          console.log(`Loaded Collection in ${tock}ms`, c);
        })
      )
      .subscribe();
  }
}
