import { IPayrollItem } from './../interfaces/payroll-item.interface';
import { ClockedType } from './clocked-type.class';
import { PayrollType } from './payroll-type.class';
import { IUser } from '../interfaces/user.interface';
import { PayrollItemStatus } from '../enums/payrollitems.enum';
import { IJob } from '../interfaces/job.interface';
import { IService } from '../interfaces/service.interface';
import { v4 as uuidv4 } from 'uuid';


export class PayrollItem implements IPayrollItem {
  public id: string;
  public job: IJob;
  public selectedService: IService;
  public timesheetId: string;
  public clockIn: firebase.firestore.Timestamp;
  public clockOut: firebase.firestore.Timestamp;
  public clockedType: ClockedType;
  public payrollType: PayrollType;
  public overtimeHrs: number;
  public status: string;
  public notes: string;
  public user: IUser;
  public qbExternalGUID: string;

  constructor(id: string,
              timesheetId: string,
              user: IUser,
              job: IJob,
              selectedService: IService,
              clockIn: firebase.firestore.Timestamp,
              clockOut: firebase.firestore.Timestamp,
              clockedType: ClockedType,
              payrollType: PayrollType,
              overtimeHrs: number) {
    this.id = id;
    this.timesheetId = timesheetId;
    this.user = user;
    this.job = job;
    this.selectedService = selectedService;
    this.status = PayrollItemStatus.PENDING;
    this.clockIn = clockIn;
    this.clockOut = clockOut;
    this.clockedType = clockedType;
    this.payrollType = payrollType;
    this.overtimeHrs = overtimeHrs;
    this.qbExternalGUID = `{${uuidv4()}}`;
  }
}
