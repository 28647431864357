import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Job } from 'src/app/models/classes/job.class';
import { Service } from 'src/app/models/classes/service.class';
import { ClockedHourType, TimesheetStatus } from 'src/app/models/enums/timesheet.enum';
import { ITimesheet } from 'src/app/models/interfaces/timesheet.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { JobService } from 'src/app/services/jobs/job.service';
import { TimesheetService } from 'src/app/services/timesheets/timesheet.service';
import { UserService } from 'src/app/services/users/user.service';
import { User } from 'src/app/models/classes/user.class';
import { map, startWith, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { IPayrollItem } from 'src/app/models/interfaces/payroll-item.interface';
import * as moment from 'moment';
import { Timesheet } from 'src/app/models/classes/timesheet.class';
import { PayrollService } from 'src/app/services/payroll/payroll.service';

@Component({
  selector: 'app-payroll-detail',
  templateUrl: './payroll-detail.component.html',
  styleUrls: ['./payroll-detail.component.scss']
})
export class PayrollDetailComponent implements OnInit {

  public payrollDetailForm: FormGroup;
  public user$: Observable<User>;
  public payrollId: string;
  public payroll: IPayrollItem;
  public services: Service[];
  public timesheetId: string;
  public qbListId: string;
  public isAdmin: boolean;
  public users: User[];
  public filteredUsers$: Observable<User[]>;
  public jobs: Job[];
  public filteredJobs$: Observable<Job[]>;
  fromDate: string;
  toDate: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private jobService: JobService,
    public authService: AuthService,
    private userService: UserService,
    private payrollService: PayrollService,
    private timesheetService: TimesheetService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.getUserRole();
    this.getUsers();
    this.getActiveJobs();
    this.buildForm();
  }

    /*
    * Handle the auto complete User search function
    */
    public getUsers() {
      this.userService.getActiveUsers().subscribe((users: User[]) => {
          this.users = users;
          this.filteredUsers$ = this.payrollDetailForm.controls.user.valueChanges
              .pipe(
                  startWith<string | User>(''),
                  map(value => typeof value === 'string' ? value : value.firstName),
                  map(name => name ? this._filterUsers(name) : this.users.slice())
              );
      });
  }

  private _filterUsers(name: string): User[] {
      const filterValue = name.toLowerCase();
      return this.users.filter(user => user.firstName.toLowerCase().indexOf(filterValue) === 0);
  }

  /*
  * Handle the auto complete Job search function
  */
  public getActiveJobs() {
    this.jobService.getActiveJobs().subscribe((jobs: Job[]) => {
      this.jobs = jobs;
      this.filteredJobs$ = this.payrollDetailForm.controls.job.valueChanges
        .pipe(
          startWith<string | Job>(''),
          map(value => typeof value === 'string' ? value : value.name),
          map(name => name ? this._filterJobs(name) : this.jobs.slice())
        );
    });
  }

  private _filterJobs(name: string): Job[] {
    const filterValue = name.toLowerCase();
    return this.jobs.filter(job => job.name.toLowerCase().indexOf(filterValue) === 0);
  }

  // Populate Job Services when the Job Changes
  public populateServices(job: Job) {
    this.services = job.services;
  }

  // Build up our Dynamic Form
  public buildForm() {
    const datePipe = new DatePipe(navigator.language);
    this.payroll = this.data.payroll;
    this.fromDate = this.data.fromDate;
    this.toDate = this.data.toDate;

    this.payrollId = this.payroll.id;
    this.timesheetId = this.payroll.timesheetId;
    this.qbListId = this.payroll.user.qbListId;

    // We need to lookup the Job since we have limited information about the job (i.e. jobId, jobName)
    this.jobService.getJob(this.payroll.job.id).subscribe((job: Job) => {
      // set the inital values to the Yellowsheets Job values
      (this.payrollDetailForm.get('job') as FormControl).setValue(job);
      this.services = job.services;
    });

    // Setup our Time and User info (if we have them)
    const user = this.payroll.user ? this.payroll.user : '';
    const selectedService = this.payroll.selectedService ? this.payroll.selectedService.name : '';
    // Since we might be passing in our
    const clockIn = this.payroll.clockIn ? this.payroll.clockIn.toDate() : new Date();
    const clockOut = this.payroll.clockOut ? this.payroll.clockOut.toDate() : new Date();

    const hourType = this.payroll.clockedType.hourType;

    const totalhrs = this.getDailyTotal(this.payroll);

    // Push our values to the form
    this.payrollDetailForm = this.fb.group({
      user: [user, [Validators.required], this.autoCompleteValidator.bind(this)],
      job: ['', [Validators.required], this.autoCompleteValidator.bind(this)],
      selectedService: [selectedService],
      clockIn: [clockIn, [Validators.required], this.checkClockInClockOutDate.bind(this)],
      clockOut: [clockOut, [Validators.required], this.checkClockInClockOutDate.bind(this)],
      hourType: [hourType],
      totalhrs: [totalhrs]
    });
  }

  getDailyTotal(payrollItem: IPayrollItem): string {
    const start = moment(payrollItem.clockIn.toDate());
    const clockOut = payrollItem.clockOut ? payrollItem.clockOut.toDate() : new Date();
    const end = moment(clockOut);
    const duration = moment.duration(end.diff(start));
    const hours = parseInt(String(duration.asHours()), 10);
    const minutes = parseInt(String(duration.asMinutes()), 10) - hours * 60;
    return `${hours}h ${minutes}m`;
  }

  autoCompleteValidator(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>  {
    const selection: any = control.value;
    return of(typeof selection !== 'string' ? null : {invalidSelection: true});
  }

  checkClockInClockOutDate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>  {
    if (this.payrollDetailForm) {
      const clockIn = this.payrollDetailForm.controls.clockIn;
      const clockOut = this.payrollDetailForm.controls.clockOut;

      // Clear out any previous errors because the newly applied clockIn or clockOut value may be valid for both date values
      if (clockIn.errors && clockIn.errors.invalidSelection !== null) {
        clockIn.setErrors(null);
      }
      if (clockOut.errors && clockOut.errors.invalidSelection !== null) {
        clockOut.setErrors(null);
      }

      const validClockIn: boolean = clockIn.value < clockOut.value;

      return of(validClockIn ? null : {invalidSelection: true});
    } else {
      return of(null);
    }
  }

  // Handle option values for users
  public displayUsername(user: User) {
    if (user) { return user.firstName + ' ' + user.lastName; }
  }

  // Handle option values for Jobs
  public displayJobName(job: Job) {
    if (job) { return job.name; }
  }

  // Helper to select the appropriate service
  public compareIds(id1: any, id2: any): boolean {
    const a1 = id1.id;
    const a2 = id2.id;
    return a1 === a2;
  }

  getUserRole() {
    this.user$ = this.authService.user$.pipe(
      tap(user => {
        this.isAdmin = this.authService.isAdmin(user);
      }));
  }

  public canDelete(): boolean {
    return this.isAdmin && this.payroll && this.payroll.clockedType.hourType === ClockedHourType.LUNCH_BREAK  && !(this.payroll.status === TimesheetStatus.APPROVED || this.payroll.status === TimesheetStatus.PROCESSED);
    // return this.isAdmin && this.payroll && !(this.payroll.status === TimesheetStatus.APPROVED || this.payroll.status === TimesheetStatus.PROCESSED);
  }

  deletePayroll(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Do you really want to delete this payroll?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // 1. Get all the user payroll items associated with the current date and user
        const sub1 = this.payrollService.getPayrollItemsByUserId(this.fromDate, this.toDate, this.qbListId).subscribe(async payrollItems => {
          sub1.unsubscribe();

          if (payrollItems.length === 0) {
            const errorResult = {
              errorCode: 404,
              message: `getPayrollItemsByTimesheetId: There are no payroll items for user (qbListId: ${this.qbListId}) and timesheetId: ${this.timesheetId}.`
            };

            console.log(`getPayrollItemsByTimesheetId: ${JSON.stringify(errorResult)}`);
            return errorResult;
          }

          // 2. Delete each of the payroll items
          //
          // QUESTION: During the deletion payroll item deletion must we also delete the QuickBook item?
          const promisesPayrollItems = [];
          payrollItems.forEach(payrollItem => {
            promisesPayrollItems.push(this.payrollService.deletePayrolltime(payrollItem.id).then(() => {}));
          });

          Promise.all(promisesPayrollItems).then(() => {
            // 3. Get all the timesheets
            const sub2 = this.timesheetService.getTimesheetsByUser(this.qbListId, this.fromDate, this.toDate).subscribe(timesheets => {
              sub2.unsubscribe();

              if (timesheets.length === 0) {
                const errorResult = {
                  errorCode: 404,
                  message: `getTimesheetsByUser: There are no timesheets for user (qbListId: ${this.qbListId}) from ${moment(this.fromDate).format('L')} to ${moment(this.toDate).format('L')} .`
                };

                console.log(`getTimesheetsByUser: ${JSON.stringify(errorResult)}`);
                return errorResult;
              }

              // 4. Reset all the timesheets from PROCESSED back to APPROVED and update the noLunchBreak
              const promisesTimesheets = [];
              timesheets.forEach(timesheet => {
                timesheet.status = TimesheetStatus.APPROVED;
                timesheet.processed = false;

                if (timesheet.id === this.timesheetId) {
                  timesheet.noLunchBreak = true;
                }

                promisesTimesheets.push(this.timesheetService.updateTimesheet(timesheet).then(() => {}));
              });

              Promise.all(promisesTimesheets).then(() => {
                // 5. Create the payroll items for the specified user
                this.payrollService.createPayrollItemsForUser(this.qbListId, this.fromDate, this.toDate);
              });
            });
          });
        });

        // // 1. Get all the user payroll items associated with the timesheet
        // const sub2 = this.payrollService.getPayrollItemsByTimesheetIdAndUserId(this.timesheetId, this.qbListId).subscribe(async payrollItems => {
        //   sub2.unsubscribe();

        //   if (payrollItems.length === 0) {
        //     const errorResult = {
        //       errorCode: 404,
        //       message: `getPayrollItemsByTimesheetId: There are no payroll items for user (qbListId: ${this.qbListId}) and timesheetId: ${this.timesheetId}.`
        //     };

        //     console.log(`getPayrollItemsByTimesheetId: ${JSON.stringify(errorResult)}`);
        //     return errorResult;
        //   }

        //   // 2. Delete each of the payroll items
        //   //
        //   // IMPORTANT: During the deletion payroll item deletion we must also delete the QuickBook item
        //   const promises = [];
        //   payrollItems.forEach(payrollItem => {
        //     promises.push(this.payrollService.deletePayrolltime(payrollItem.id).then(() => {
        //     }));
        //   });

        //   Promise.all(promises).then(() => {
        //     // 3. Get the timesheet
        //     const sub2 = this.timesheetService.getTimesheet(this.timesheetId).subscribe(timesheet => {
        //       sub2.unsubscribe();

        //       // 4. Reset the timesheet status to TimesheetStatus.APPROVED
        //       //
        //       // NOTE: This step is necessary so that we can re-create the payroll items
        //       timesheet.status = TimesheetStatus.APPROVED;
        //       this.timesheetService.updateTimesheet(timesheet).then(timesheetResult => {
        //         // 5. Create the payroll items for the specified user
        //         this.payrollService.createPayrollItemsForUser(this.qbListId, this.fromDate, this.toDate, false);
        //       })
        //       .catch(error => {
        //           console.log(`!!! ERROR: updateTimesheet failed: ${error}`);
        //       });
        //     });
        //   });
        // });

        // this.payroll.clockedType.hourType = ClockedHourType.NO_LUNCH_BREAK;
        // this.payrollService.updatePayrollItem(this.payroll).then(updateResult => {
        //   console.log(`deletePayroll: Successfully updated payroll ${this.payroll.id}`);
        //   if (this.router.url.indexOf('/payroll') > 0) {
        //     this.router.navigateByUrl('payroll');
        //   }
        // });
      }
    });
  }
}
