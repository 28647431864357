import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { take, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormanGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router
  )  { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.authService.user$.pipe(
      take(1),
      map(user => user && this.authService.isForeman(user) ? true : false),
      tap(isAdmin => {
        if (!isAdmin) {
          console.error('Access denied - Foreman only');
        }
      })
    );
  }
}
