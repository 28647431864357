
<mat-form-field>
    <input matInput type="text" [(ngModel)]="searchText" (keyup)="applyFilter($event.target.value)" placeholder="Search Users">
</mat-form-field>
<mat-checkbox class="pl10" [(ngModel)]="showAllUsers" (change)="getUsers()" (click)="searchText=''">Show All Users?</mat-checkbox>

<div class="mat-elevation-z8">
    <table class="full-width" mat-table [dataSource]=dataSource matSort matSortActive="firstName" matSortDirection="asc">
        
        <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
            <td mat-cell *matCellDef="let user">                
                <mat-icon *ngIf="user.isActive" [color]="user.qbListId !== '' ? 'primary' : 'warn'">check_circle</mat-icon>                
            </td>
        </ng-container>
        
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email </th>
            <td mat-cell *matCellDef="let user"><a routerLink="/userdetail/{{ user.id }}">{{user.email}}</a></td>
        </ng-container>       

        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First Name </th>
            <td mat-cell *matCellDef="let user">{{user.firstName}}</td>
        </ng-container>

        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last Name </th>
            <td mat-cell *matCellDef="let user">{{user.lastName}}</td>
        </ng-container>

        <ng-container matColumnDef="role">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
            <td mat-cell *matCellDef="let user">{{user.role}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[15, 25, 50]"></mat-paginator>
</div>
<div class="fab-container">
    <button mat-mini-fab [routerLink]="['/newuser']" color="primary">
  <mat-icon aria-label="New User">add</mat-icon>
</button>
</div>