import { IUser } from '../interfaces/user.interface';

export class User implements IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isActive: boolean;
  role: string;
  regularTime: any;
  overTime: any;
  holidayTime : any;
  funeralTime : any;
  sickTime : any;
  vacationTime : any;
  wageTypes: any;
  qbListId: string;

  getUserName(): string {
    return this.firstName + ' ' + this.lastName;
  }
}
