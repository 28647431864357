<div class="mb20" flFlex flexLayout="row" flexLayout.lt-md="column">
  <div fxFlex>
      <form>
          <mat-form-field class="pr20">
              <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search By Name" />
          </mat-form-field>
      </form>
      <div class="mat-elevation-z8">
          <table class="full-width" mat-table [dataSource]=timesheets matSort>

              <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                  <td mat-cell *matCellDef="let timesheet"><a (click)="openTimesheet(timesheet)">{{timesheet.user.firstName}} {{timesheet.user.lastName}}</a></td>
              </ng-container>

              <ng-container matColumnDef="jobName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> Job </th>
                  <td mat-cell *matCellDef="let timesheet"><a (click)="openMap(timesheet)">{{timesheet.job?.name}}</a></td>
              </ng-container>

              <ng-container matColumnDef="selectedService">
                  <th mat-header-cell *matHeaderCellDef> Service </th>
                  <td mat-cell *matCellDef="let timesheet">{{timesheet.selectedService?.name}}</td>
              </ng-container>

              <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef> Date </th>
                  <td mat-cell *matCellDef="let timesheet">
                      <div>{{timesheet.clockIn.toDate() | date:'MM/dd/yyyy':'UTC'}}</div>
                  </td>
              </ng-container>

              <ng-container matColumnDef="clockIn">
                  <th mat-header-cell *matHeaderCellDef> Clock In </th>
                  <td mat-cell *matCellDef="let timesheet">
                      <div>{{timesheet.clockIn.toDate() | date: 'h:mmaaaaa'}}</div>
                  </td>
              </ng-container>

              <!-- Default Table lines -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

          </table>
          <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
      </div>

  </div>
</div>