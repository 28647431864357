import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource, MatPaginator } from '@angular/material';
import { IYellowsheet } from 'src/app/models/interfaces/yellowsheet.interface';
import { YellowsheetService } from 'src/app/services/yellowsheets/yellowsheet.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-yellowsheet-list',
  templateUrl: './yellowsheet-list.component.html',
  styleUrls: ['./yellowsheet-list.component.scss']
})
export class YellowsheetListComponent implements AfterViewInit {

  displayedColumns: string[] = ['date'];
  yellowsheets: MatTableDataSource<IYellowsheet>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @Input() jobId: string;
  @Input() jobName: string;

  constructor(
    public authService: AuthService,
    private yellowsheetsService: YellowsheetService
  ) { }

  ngAfterViewInit() {
    this.getYellowsheets();
  }

  public applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.yellowsheets.filter = filterValue;
  }

  public getYellowsheets() {
    this.yellowsheetsService.getYellowsheets(this.jobId).subscribe(yellowsheets => {
      this.yellowsheets = new MatTableDataSource(yellowsheets);
      this.yellowsheets.sort = this.sort;
      this.yellowsheets.paginator = this.paginator;
    });
  }
}
