<div class="row" *ngIf="loading">
    <div class="column center">
        <mat-spinner></mat-spinner>
        <div style="margin-top: 15px;">Saving User Details</div>
    </div>    
</div>
<div class="mb-20" *ngIf="!loading">
    <ol class="breadcrumb">
        <li><a [routerLink]="['/users']">Users</a></li>
        <li class="active">{{fullName}}</li>
    </ol>
    <!-- Row -->
    <div class="row">
        <!-- Left Column -->
        <div class="column">
            <!-- Edit User Form -->
            <form class="user-form" [formGroup]="userForm" *ngIf="user$ | async;" (ngSubmit)="updateUser()">
                <!-- Email -->
                <mat-form-field class="full-width">
                    <input matInput formControlName="email" placeholder="Email" />
                </mat-form-field>
                
                <mat-form-field class="full-width">
                    <input matInput formControlName="qbListId" placeholder="QB List Id" />
                </mat-form-field>

                <!-- First Name -->
                <mat-form-field class="full-width">
                    <input matInput formControlName="firstName" placeholder="First Name" />
                </mat-form-field>
                <!-- Last Name -->
                <mat-form-field class="full-width">
                    <input matInput formControlName="lastName" placeholder="Last Name" />
                </mat-form-field>
                <!-- is Active -->
                <div class="full-width">
                    <mat-checkbox formControlName="isActive">Is Active</mat-checkbox>
                </div>
                <!-- Role -->
                <mat-form-field class="full-width">
                    <mat-select formControlName="role">
                        <mat-option *ngFor="let role of (roles | async)" [value]="role">
                            {{role}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Regular Time -->
                <mat-form-field class="full-width">
                    <mat-select formControlName="regularTime" placeholder="Regular Time" [compareWith]="compareWages">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let wageType of (wageTypes)" [value]="wageType">
                            {{wageType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Over Time -->
                <mat-form-field class="full-width">
                    <mat-select formControlName="overTime" placeholder="Over Time" [compareWith]="compareWages">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let wageType of (wageTypes)" [value]="wageType">
                            {{wageType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                 <!-- Holiday Time -->
                 <mat-form-field class="full-width">
                    <mat-select formControlName="holidayTime" placeholder="Holiday Time" [compareWith]="compareWages">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let wageType of (wageTypes)" [value]="wageType">
                            {{wageType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                 <!-- Funeral Time -->
                 <mat-form-field class="full-width">
                    <mat-select formControlName="funeralTime" placeholder="Funeral Time" [compareWith]="compareWages">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let wageType of (wageTypes)" [value]="wageType">
                            {{wageType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- Sick Time -->
                <mat-form-field class="full-width">
                    <mat-select formControlName="sickTime" placeholder="Sick Time" [compareWith]="compareWages">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let wageType of (wageTypes)" [value]="wageType">
                            {{wageType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                 <!-- Vacation Time -->
                 <mat-form-field class="full-width">
                    <mat-select formControlName="vacationTime" placeholder="Vacation Time" [compareWith]="compareWages">
                        <mat-option [value]="null"></mat-option>
                        <mat-option *ngFor="let wageType of (wageTypes)" [value]="wageType">
                            {{wageType.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="row button-row">
                    <button routerLink="/users" mat-raised-button color="secondary">Cancel</button>
                    <button [disabled]="userForm.invalid" type="submit" mat-raised-button color="primary" class="w-100">Save</button>
                </div>
            </form>
            <!-- // Edit User Form -->
        </div>
        <!-- // Left Column -->
        <!-- Right Column -->
        <div class="column">
            <!-- Change Password Form -->
            <form class="user-form" [formGroup]="changePasswordForm" *ngIf="user$ | async;" (ngSubmit)="changePassword()">
                <!-- Password -->
                <mat-form-field class="full-width">
                    <input matInput type="password" formControlName="password" placeholder="Password" onfocus="this.removeAttribute('readonly');" readonly />
                </mat-form-field>
                <!-- Password Confirmation -->
                <mat-form-field class="full-width">
                    <input matInput type="password" formControlName="confirmPassword" placeholder="Confirm Password" onfocus="this.removeAttribute('readonly');" readonly />
                    <mat-error *ngIf="changePasswordForm.get('confirmPassword').errors && changePasswordForm.get('confirmPassword').errors.mustMatch ">
                        Passwords do not match
                    </mat-error>
                </mat-form-field>
                <div class="row button-row">
                    <button type="submit" [disabled]="changePasswordForm.invalid" mat-raised-button color="warn" class="w-100">Change Password</button>
                </div>
            </form>
            <!-- // Change Password Form -->
        </div>
        <!-- // Right Column -->
    </div>
    <!-- // Row -->
    <!-- <pre>FormData {{ userForm.value | json }}</pre> -->

</div>