<form class="job-detail-form mb-20" [formGroup]="jobForm" *ngIf="job$ | async;">
    <div class="content" rolesAndPermissionsRelatedContent *ngIf="user$ | async;">
        <ol class="breadcrumb">
            <li><a [routerLink]="['/jobs']">Jobs</a></li>
            <li class="active">{{jobName}}</li>
        </ol>
        <!-- Row -->
        <div class="row">
            <!-- Left Column -->
            <div class="column">
                <!-- Customer Name -->
                <mat-form-field>
                    <input matInput formControlName="customerName" placeholder="Customer Name" />
                </mat-form-field>

                <!-- Job Name -->
                <mat-form-field>
                    <input matInput formControlName="name" placeholder="Job Name" />
                </mat-form-field>
                <!-- Job Address -->
                <mat-form-field>
                    <input matInput formControlName="addressLine1" placeholder="Address Line 1" />
                    <mat-icon matSuffix  [color]="job$.longitude !== '' ? 'primary' : 'warn'" (click)="openMap()">pin_drop</mat-icon>
                </mat-form-field>

                <mat-form-field>
                    <input matInput formControlName="addressLine2" placeholder="Address Line 2" />
                </mat-form-field>

                <mat-form-field>
                    <input matInput formControlName="city" placeholder="City" />
                </mat-form-field>

                <mat-form-field>
                    <input matInput formControlName="state" placeholder="State" />
                </mat-form-field>

                <mat-form-field>
                    <input matInput formControlName="zip" placeholder="Zip" />
                </mat-form-field>
                <!-- // Job Address -->
                <!-- is Active -->
                <div>
                    <mat-checkbox formControlName="isActive">Is Active</mat-checkbox>
                </div>
                <mat-form-field>
                    <input matInput formControlName="qbListId" placeholder="QB List Id" />
                </mat-form-field>

            </div>
            <!-- // Left Column -->
            <!-- Right Column -->
            <div class="column">
                <mat-form-field>
                    <mat-select formControlName="foreman" [compareWith]="compareIds">
                        <mat-option *ngFor="let foreman of (foremen$ | async)" [value]="getFormanValue(foreman)">
                            {{foreman.lastName}}, {{foreman.firstName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-select formControlName="jobClass" [compareWith]="compareNames">
                        <mat-option *ngFor="let jobClass of (classes$ | async)" [value]="jobClass">
                            {{jobClass.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="pb10">
                    Services
                </div>

                <div *ngFor="let service of services$ | async; let i = index">
                    <mat-checkbox (change)="onServicesChange($event)" [checked]="isServiceChecked(service.id)" [value]="service">{{service.name}}</mat-checkbox>
                </div>

                <div class="row">
                    <div class="column pl0">
                        Hours Allocated

                        <mat-form-field>
                            <input matInput formControlName="sitePrepHours" placeholder="Site Prep Hrs" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput formControlName="masonaryHours" placeholder="Masonary Hrs" />
                        </mat-form-field>
                        <mat-form-field>
                            <input matInput formControlName="plantingHours" placeholder="Planting Hrs" />
                        </mat-form-field>
                    </div>

                    <div class="column pl0">
                        Hours Logged

                        <mat-form-field disabled>
                            <input matInput formControlName="sitePrepHoursLogged" placeholder="Site Prep Hrs Logged" readonly />
                        </mat-form-field>
                        <mat-form-field disabled>
                            <input matInput formControlName="masonaryHoursLogged" placeholder="Masonary Hrs Logged" readonly />
                        </mat-form-field>
                        <mat-form-field disabled>
                            <input matInput formControlName="plantingHoursLogged" placeholder="Planting Hrs Logged" readonly />
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <!-- // Right Column -->
        </div>
        <!-- // Row -->

        <div class="row">
            <!-- Left Column -->
            <div class="column">
                <!-- Job Materials -->
                <div class="header">
                    <h3 class="title">Material</h3>
                    <!-- Material Add Button -->
                    <div class="action-button">
                        <button mat-mini-fab (click)="addMaterial()" color="primary">
              <mat-icon aria-label="New Job">add</mat-icon>
            </button>
                    </div>
                </div>
                <!-- Material Collection -->
                <div formArrayName="materials" class="form-array">
                    <div *ngFor="let material of jobForm.controls.materials.controls; let i=index" [formGroupName]="i">
                        <!-- Material Name Select-->
                        <mat-form-field>
                            <mat-select formControlName="id">
                                <mat-option *ngFor="let material of materialList" [value]="material.id">
                                    {{material.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- Material Allocated Input -->
                        <mat-form-field>
                            <input matInput formControlName="allocated" placeholder="Allocated">
                        </mat-form-field>
                        <!-- Materila Delete -->
                        <a (click)="deleteMaterial(i)">
                            <mat-icon color="warn" aria-label="Delete Material">delete_forever</mat-icon>
                        </a>
                    </div>
                </div>
                <!-- // Material Collection -->
                <!-- // Job Materials -->

            </div>
            <!-- // Left Column -->
            <!-- Right Column -->
            <div class="column">
                <!-- Job Equipment -->
                <div class="header">
                    <h3 class="title">Equipment</h3>
                    <!-- Add Equipment Button -->
                    <div class="action-button">
                        <button mat-mini-fab (click)="addEquipment()" color="primary">
              <mat-icon aria-label="New Job">add</mat-icon>
            </button>
                    </div>
                </div>
                <!-- Equipmment Collection -->
                <div class="form-array" formArrayName="equipment">
                    <div *ngFor="let equipment of jobForm.controls.equipment.controls; let i=index" [formGroupName]="i">
                        <!-- Equipment Name -->
                        <mat-form-field>
                            <mat-select formControlName="id">
                                <mat-option *ngFor="let equipment of equipmentList" [value]="equipment.id">
                                    {{equipment.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- Equipment Allocation -->
                        <mat-form-field>
                            <input matInput formControlName="allocated" placeholder="Allocated">
                        </mat-form-field>
                        <!-- Delete Equipment -->
                        <a (click)="deleteEquipment(i)">
                            <mat-icon color="warn" aria-label="Delete Equipment">delete_forever</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
            <!-- // Right Column -->
        </div>

        <!-- Consumed Material and Equipment -->
        <div class="row">
            <!-- Left Column -->
            <div class="column">
                <!-- Job Materials -->
                <div class="header">
                    <h3 class="title">Consumed Material</h3>
                </div>
                <!-- Material Collection -->
                <div formArrayName="materialsConsumed" class="form-array">
                    <div *ngFor="let material of jobForm.controls.materialsConsumed.controls; let i=index" [formGroupName]="i">
                        <!-- Material Name Select-->
                        <mat-form-field>
                            <mat-select formControlName="id" disabled>
                                <mat-option *ngFor="let material of materialList" [value]="material.id" disabled>
                                    {{material.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- Material Delivered Input -->
                        <mat-form-field disabled>
                            <input matInput formControlName="delivered" placeholder="Delivered" readonly>
                        </mat-form-field>
                        <!-- Material Installed Input -->
                        <mat-form-field disabled>
                            <input matInput formControlName="installed" placeholder="Installed" readonly>
                        </mat-form-field>
                    </div>
                </div>
                <!-- // Material Collection -->
                <!-- // Job Materials -->

            </div>
            <!-- // Left Column -->
            <!-- Right Column -->
            <div class="column">
                <!-- Job Equipment -->
                <div class="header">
                    <h3 class="title">Consumed Equipment</h3>
                </div>
                <!-- Equipmment Collection -->
                <div class="form-array" formArrayName="equipmentConsumed">
                    <div *ngFor="let equipment of jobForm.controls.equipmentConsumed.controls; let i=index" [formGroupName]="i">
                        <!-- Equipment Name -->
                        <mat-form-field>
                            <mat-select formControlName="id" disabled>
                                <mat-option *ngFor="let eq of equipmentList" [value]="eq.id" disabled>
                                    {{eq.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- Hours Allocation -->
                        <mat-form-field disabled>
                            <input matInput formControlName="hours" placeholder="Hours" readonly>
                        </mat-form-field>
                    </div>
                </div>
            </div>
            <!-- // Right Column -->
        </div>
        <!-- // Consumed Material and Equipment -->
        <!-- Jobs Yellowsheets -->
        <div *ngIf="jobId" class="row">
            <div class="column">


                <div class="header">
                    <h3 class="title">Yellowsheets</h3>
                    <div class="action-button">
                        <button mat-mini-fab (click)="addYellowsheet()" color="primary" class="exclude-from-roles-and-permissions">
              <mat-icon aria-label="New Yellowsheet">add</mat-icon>
            </button>
                    </div>

                </div>

                <app-yellowsheet-list [jobId]="jobId"></app-yellowsheet-list>
            </div>
        </div>
        <div class="row button-row">
            <button routerLink="/jobs" mat-raised-button color="secondary">Cancel</button>
            <button type="submit" [disabled]="jobForm.invalid" (click)="updateJob()" mat-raised-button color="primary"><mat-icon>done</mat-icon> Save</button>
            <button class="pull-right" type="submit" *ngIf="canDelete()" (click)="deleteJob()" mat-raised-button color="warn"><mat-icon>delete_forever</mat-icon> Delete</button>
        </div>
        <!--
            <pre>FormData {{ jobForm.value | json }}</pre>
    -->
    </div>
</form>