import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { OnRolesAndPermissions } from 'ngx-auto-disable-forms';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JobClass } from 'src/app/models/classes/job-class.class';
import { Job } from 'src/app/models/classes/job.class';
import { Yellowsheet } from 'src/app/models/classes/yellowsheets.class';
import { IUser } from 'src/app/models/interfaces/user.interface';
import { AuthService } from 'src/app/services/auth/auth.service';
import { JobService } from 'src/app/services/jobs/job.service';
import { YellowsheetService } from 'src/app/services/yellowsheets/yellowsheet.service';
import { Equipment } from '../../../models/classes/equipment.class';
import { Material } from '../../../models/classes/material.class';
import { Service } from '../../../models/classes/service.class';
import { Timesheet } from '../../../models/classes/timesheet.class';
import { User } from '../../../models/classes/user.class';
import { EquipmentService } from '../../../services/equipment/equipment.service';
import { JobClassService } from '../../../services/job-class/job-class.service';
import { MaterialService } from '../../../services/materials/material.service';
import { TimesheetService } from '../../../services/timesheets/timesheet.service';
import { UserService } from '../../../services/users/user.service';
import { YellowsheetCreateComponent } from '../../yellowsheets/yellowsheet-create/yellowsheet-create.component';
import { ServiceService } from './../../../services/services/service.service';
import { GoogleMapService } from 'src/app/services/google-maps/google-map.service';
import { GoogleMapComponent } from '../../google-map/google-map.component';
import { IJob } from 'src/app/models/interfaces/job.interface';

@Component({
    selector: 'app-job-detail',
    templateUrl: './job-detail.component.html',
    styleUrls: ['./job-detail.component.scss']
})

export class JobDetailComponent implements OnInit, OnDestroy, OnRolesAndPermissions {
    public subs: Subscription[] = [];
    public job$: Observable<Job>;
    public job: IJob;
    public user: IUser;
    public jobForm: FormGroup;
    public jobId: string;
    public jobName: string;
    public materialList: Material[];
    public equipmentList: Equipment[];
    public services$: Observable<Service[]>;
    public classes$: Observable<JobClass[]>;
    public foremen$: Observable<User[]>;
    public user$: Observable<User>;
    public timesheets$: Observable<Timesheet[]>;
    public isAdmin: boolean;
    public qbListId: string;

    constructor(
        private fb: FormBuilder,
        private activeRoute: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private jobService: JobService,
        private userService: UserService,
        private serviceService: ServiceService,
        private materialService: MaterialService,
        private jobClassService: JobClassService,
        private equipmentService: EquipmentService,
        private timesheetService: TimesheetService,
        private googleMapsService: GoogleMapService,
        private dialog: MatDialog,
        private yellowsheetService: YellowsheetService
    ) { }

    ngOnInit() {
        this.getUserRole();
        this.buildForm();
        this.getServices();
        this.getJobClasses();
        this.getMaterialList();
        this.getEquipmentList();
        this.getForeman();
        this.getJob();
    }

    ngOnDestroy() {
        this.subs.forEach(sub => {
            sub.unsubscribe();
        });
    }

    getUserRole() {
        this.user$ = this.authService.user$.pipe(
            tap(user => {
                this.isAdmin = this.authService.isAdmin(user);
            }));
    }

    isViewReadOnly(): boolean {
        return !this.isAdmin; // Make readonly if the user isn't an admin
    }

    buildForm(): void {
        this.jobForm = this.fb.group({
            addressLine1: ['', Validators.required],
            addressLine2: '',
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],
            name: ['', Validators.required],
            isActive: [false, Validators.required],
            sitePrepHours: '',
            masonaryHours: '',
            plantingHours: '',
            sitePrepHoursLogged: '',
            masonaryHoursLogged: '',
            plantingHoursLogged: '',
            customerName: ['', Validators.required],
            jobClass: { id: '', name: '' },
            foreman: { id: '', email: '' },
            services: this.fb.array([]),
            materials: this.fb.array([]),
            equipment: this.fb.array([]),
            materialsConsumed: this.fb.array([]),
            equipmentConsumed: this.fb.array([]),
            qbListId: ''
        });
    }

    getJob(): void {
        this.subs.push(
            this.activeRoute.params.subscribe(params => {
                this.jobId = params.jobId;
                this.job$ = this.jobService.getJob(this.jobId).pipe(
                    tap(job => {
                        // We have instances where the "id" isn't being saved to the job, this hack will ensure it gets updated
                        job.id = this.jobId;
                        this.job = job;
                        this.jobForm.patchValue(job);
                        this.getTimesheets();
                        this.jobName = job.name;
                        this.populateServices(job.services);
                        this.populateMaterials(job.materials);
                        this.populateEquipment(job.equipment);
                        this.populateMaterialsConsumed(job.materialsConsumed);
                        this.populateEquipmentConsumed(job.equipmentConsumed);
                        if( this.job.latitude === undefined) {
                            let status = this.googleMapsService.updateGeoCoordinates(job);
                            console.log(status);
                        }                        
                    })
                );
            })
        );
    }

    getTimesheets() {
        this.timesheetService.getJobTimesheets(this.jobId).subscribe(data => {
            const sitePrepTimesheets = data.filter(t => t.selectedService.name === 'Site Prep');
            const masonryTimesheets = data.filter(t => t.selectedService.name === 'Masonry');
            const plantingTimesheets = data.filter(t => t.selectedService.name === 'Planting');

            let sitePrepMinutesLogged = 0;
            let masonaryMinutesLogged = 0;
            let plantingMinutesLogged = 0;

            const logged = {
                sitePrepHoursLogged: '',
                masonaryHoursLogged: '',
                plantingHoursLogged: ''
            };

            sitePrepTimesheets.forEach(timesheet => {
                const start = moment(timesheet.clockIn.toDate());
                const clockOut = timesheet.clockOut ? timesheet.clockOut.toDate() : new Date();
                const end = moment(clockOut);
                const duration = moment.duration(end.diff(start));
                const minutes = parseInt(String(duration.asMinutes()), 10);

                sitePrepMinutesLogged = sitePrepMinutesLogged + minutes;
            });

            masonryTimesheets.forEach(timesheet => {
                const start = moment(timesheet.clockIn.toDate());
                const clockOut = timesheet.clockOut ? timesheet.clockOut.toDate() : new Date();
                const end = moment(clockOut);
                const duration = moment.duration(end.diff(start));
                const minutes = parseInt(String(duration.asMinutes()), 10);

                masonaryMinutesLogged = masonaryMinutesLogged + minutes;
            });

            plantingTimesheets.forEach(timesheet => {
                const start = moment(timesheet.clockIn.toDate());
                const clockOut = timesheet.clockOut ? timesheet.clockOut.toDate() : new Date();
                const end = moment(clockOut);
                const duration = moment.duration(end.diff(start));
                const minutes = parseInt(String(duration.asMinutes()), 10);

                plantingMinutesLogged = plantingMinutesLogged + minutes;
            });

            if (sitePrepMinutesLogged && !isNaN(sitePrepMinutesLogged)) {
                logged.sitePrepHoursLogged = (sitePrepMinutesLogged / 60).toFixed(2);
            }

            if (masonaryMinutesLogged && !isNaN(masonaryMinutesLogged)) {
                logged.masonaryHoursLogged = (masonaryMinutesLogged / 60).toFixed(2);
            }

            if (plantingMinutesLogged && !isNaN(plantingMinutesLogged)) {
                logged.plantingHoursLogged = (plantingMinutesLogged / 60).toFixed(2);
            }

            this.jobForm.patchValue(logged);
        });
    }

    public deleteJob() {
        this.jobService.deleteJob(this.jobId)
        .then(result => {
            this.router.navigateByUrl('jobs');
        })
        .catch(error => {
            console.log(`!!! ERROR: deleteJob failed: ${error}`);
        });
    }

    public canDelete(): boolean {
        return this.isAdmin;
    }

    addYellowsheet() {
        // Get the date from the user
        const dialogConfig = new MatDialogConfig();
        const dialogRef = this.dialog.open(YellowsheetCreateComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(date => {
            // Determine if we should create a new yellowsheet
            // or navigate to an existing one
            if (!date) { return; }
            const yellowsheet = new Yellowsheet(this.jobId, this.jobName, date.value);
            this.yellowsheetService.createYellowsheet(yellowsheet).then(yellowsheetId => {
                // Navigate to the new or existing yellowsheet
                this.router.navigateByUrl('/jobdetail/' + this.jobId + '/yellowsheet/' + yellowsheetId);
            });
        });
    }

    compareIds(id1: any, id2: any): boolean {
        const a1 = id1.id;
        const a2 = id2.id;
        return a1 === a2;
    }

    compareNames(name1: any, name2: any): boolean {
        const a1 = name1.name;
        const a2 = name2.name;
        return a1 === a2;
    }

    /*
    * Foreman
    */
    getForeman(): void {
        this.foremen$ = this.userService.getForemanUsers();
    }
    getFormanValue(foreman: User) {
        return { id: foreman.id, email: foreman.email, firstName: foreman.firstName, lastName: foreman.lastName };
    }

    /*
    * Job Classes
    */
    getJobClasses(): void {
        this.classes$ = this.jobClassService.getClasses();
    }

    /*
    * Services
    */
    get jobServices(): FormArray {
        return this.jobForm.get('services') as FormArray;
    }

    // Get our Services from the admin table
    getServices(): void {
        this.services$ = this.serviceService.getServices();
    }

    // Add or remove from the Dynamic form so we only save the services selected.
    onServicesChange(event) {
        if (event.checked) {
            this.jobServices.push(new FormControl(event.source.value));
        } else {
            const i = this.jobServices.controls.findIndex(x => x.value === event.source.value);
            this.jobServices.removeAt(i);
        }
    }

    // Push our existing values onto the form so we don't wipe out our data
    populateServices(services: Service[]) {
        services.forEach(service => {
            this.jobServices.push(new FormControl(service));
        });
    }

    isServiceChecked(serviceId: string): boolean {
        return this.jobServices ? this.jobServices.value.map(x => x.id).indexOf(serviceId) > -1 : false;
    }

    /*
    * Materials
    */
    getMaterialList(): void {
        this.materialService.getMaterials().subscribe(materials => {
            this.materialList = materials;
        });
    }

    populateMaterials(materials: Material[]) {
        materials.forEach(material => {
            (this.jobForm.get('materials') as FormArray).push(this.fb.group({
                id: material.id,
                allocated: material.allocated
            }));
        });
    }

    populateMaterialsConsumed(materials: Material[]) {
        try {
            materials.forEach(material => {
                (this.jobForm.get('materialsConsumed') as FormArray).push(this.fb.group({
                    id: material.id,
                    delivered: material.delivered,
                    installed: material.installed
                }));
            });
        } catch (ex) {

        }
    }

    get material(): FormGroup {
        return this.fb.group({
            id: ['', Validators.required],
            allocated: ['', Validators.required]
        });
    }

    addMaterial(): void {
        (this.jobForm.get('materials') as FormArray).push(this.material);
    }

    deleteMaterial(i: number): void {
        (this.jobForm.get('materials') as FormArray).removeAt(i);
    }

    /*
    * Equipment
    */
    getEquipmentList(): void {
        this.equipmentService.getEquipment().subscribe(equipment => {
            this.equipmentList = equipment;
        });
    }

    populateEquipment(equipment: Equipment[]) {
        equipment.forEach(equip => {
            (this.jobForm.get('equipment') as FormArray).push(this.fb.group({
                id: equip.id,
                allocated: equip.allocated
            }));
        });
    }

    populateEquipmentConsumed(equipment: Equipment[]) {
        try {
            equipment.forEach(equip => {
                (this.jobForm.get('equipmentConsumed') as FormArray).push(this.fb.group({
                    id: equip.id,
                    hours: equip.hours
                }));
            });
        } catch (e) {

        }
    }

    get equipment(): FormGroup {
        return this.fb.group({
            id: ['', Validators.required],
            allocated: ['', Validators.required]
        });
    }
    
    openMap() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            type: 'JOB',
            job : this.job
        };
        this.dialog.open(GoogleMapComponent, dialogConfig);
    }

    addEquipment(): void {
        (this.jobForm.get('equipment') as FormArray).push(this.equipment);
    }


    deleteEquipment(i: number) {
        (this.jobForm.get('equipment') as FormArray).removeAt(i);
    }


    updateJob() {
        this.jobService.updateJob(this.jobId, this.jobForm.value)
            .then(() => {
                this.router.navigateByUrl('jobs');
            });
    }

}
