<div id="sidebar">
    <mat-nav-list *ngIf="authService.user$ | async as user">
        <a mat-list-item [routerLinkActive]="'active'" [routerLink]="['/jobs']">
            <mat-icon class="sidenav-icon">dashboard</mat-icon> Jobs
        </a>
        <a mat-list-item *ngIf="user.role == 'Foreman' || user.role == 'Admin'" [routerLinkActive]="'active'" [routerLink]="['/approvals']">
            <mat-icon class="sidenav-icon">schedule</mat-icon> Approvals
        </a>
        <div class="nested-menu" *ngIf="user.role == 'Admin'">
            <a mat-list-item (click)="addExpandClass('pages')">
                <mat-icon class="sidenav-icon">{{ showMenu === 'pages' ? 'remove' : 'add' }}</mat-icon> Admin               
            </a>
            <ul class="nested submenu" [class.expand]="showMenu === 'pages'">
                <li>
                    <a mat-list-item *ngIf="user.role == 'Admin'" [routerLinkActive]="'active'" [routerLink]="['/whosworking']">
                        <mat-icon class="sidenav-icon">person_search</mat-icon> Who's Working
                    </a>
                </li>
                <li>
                    <a mat-list-item *ngIf="user.role == 'Admin'" [routerLinkActive]="'active'" [routerLink]="['/payroll']">
                        <mat-icon class="sidenav-icon">payments</mat-icon> Payroll
                    </a>
                </li>
                <li>
                    <a mat-list-item *ngIf="user.role == 'Admin'" [routerLinkActive]="'active'" [routerLink]="['/users']">
                        <mat-icon class="sidenav-icon">person</mat-icon> Users
                    </a>
                </li>
                <li>
                    <a mat-list-item *ngIf="user.role == 'Admin'" [routerLinkActive]="'active'" [routerLink]="['/materials']">
                        <mat-icon class="sidenav-icon">texture</mat-icon> Materials
                    </a>
                </li>
                <li>
                    <a mat-list-item *ngIf="user.role == 'Admin'" [routerLinkActive]="'active'" [routerLink]="['/equipment']">
                        <mat-icon class="sidenav-icon">airport_shuttle</mat-icon> Equipment
                    </a>
                </li>
            </ul>
        </div>
    </mat-nav-list>
</div>