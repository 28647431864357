<form class="material-form mb-20" [formGroup]="materialForm">
    <ol class="breadcrumb">
        <li><a [routerLink]="['/materials']">Materials</a></li>
        <li class="active">{{materialName}}</li>
    </ol>
    <!-- Row -->
    <div class="row">
        <!-- Left Column -->
        <div class="column">
            <!-- Customer Name -->
            <mat-form-field>
                <input matInput formControlName="name" placeholder="Material Name" />
            </mat-form-field>
            <div class="row button-row">
                <button routerLink="/materials" mat-raised-button color="secondary">Cancel</button>
                <button type="submit" [disabled]="materialForm.invalid" (click)="updateMaterial()" mat-raised-button color="primary"><mat-icon>done</mat-icon> Save</button>
            </div>
        </div>
    </div>
    <!--
  <pre>FormData {{ materialForm.value | json }}</pre>
-->
</form>