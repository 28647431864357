import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Firebase Specific
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireFunctionsModule } from '@angular/fire/functions';

// Our App Components
import { LoginComponent } from './components/auth/login/login.component';
import { JobListComponent } from './components/jobs/job-list/job-list.component';
import { JobDetailComponent } from './components/jobs/job-detail/job-detail.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { UserCreateComponent } from './components/users/user-create/user-create.component';
import { YellowsheetDetailComponent } from './components/yellowsheets/yellowsheet-detail/yellowsheet-detail.component';
import { MaterialListComponent } from './components/materials/material-list/material-list.component';
import { MaterialDetailComponent } from './components/materials/material-detail/material-detail.component';
import { EquipmentListComponent } from './components/equipment/equipment-list/equipment-list.component';
import { EquipmentDetailComponent } from './components/equipment/equipment-detail/equipment-detail.component';
import { TimesheetDetailComponent } from './components/timesheets/timesheet-detail/timesheet-detail.component';
import { YellowsheetListComponent } from './components/yellowsheets/yellowsheet-list/yellowsheet-list.component';
import { TimesheetListComponent } from './components/timesheets/timesheet-list/timesheet-list.component';
import { TimesheetApprovalListComponent } from './components/timesheets/timesheet-approval-list/timesheet-approval-list.component';
import { PayrollListComponent } from './components/payroll/payroll-list/payroll-list.component';
import { PayrollDetailComponent } from './components/payroll/payroll-detail/payroll-detail.component';
import { WhosWorkingComponent } from './components/timesheets/whos-working/whos-working.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
// Layout Components
import { HeaderComponent } from './components/navigation/header/header.component';
import { SideNavComponent } from './components/navigation/side-nav/side-nav.component';
import { LoginLayoutComponent } from './layouts/login/login-layout.component';
import { HomeLayoutComponent } from './layouts/home/home-layout.component';
// Guards
import { AdminGuard } from './guards/admin.guard';
import { FormanGuard } from './guards/forman.guard';
import { AuthGuard } from './guards/auth.guard';
// Services
import { AuthService } from './services/auth/auth.service';
import { JobService } from './services/jobs/job.service';
import { TimesheetService } from './services/timesheets/timesheet.service';
import { PayrollService } from './services/payroll/payroll.service';
import { FirestoreService } from './services/firestore/firestore.service';
import { MaterialService } from './services/materials/material.service';
import { EquipmentService } from './services/equipment/equipment.service';
import { ServiceService } from './services/services/service.service';
import { JobClassService } from './services/job-class/job-class.service';

// Material Design
import {
  MatTableModule,
  MatPaginatorModule,
  MatIconModule,
  MatSelectModule,
  MatOptionModule,
  MatListModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatGridListModule,
  MatButtonModule,
  MatCheckboxModule,
  MatInputModule,
  MatSortModule,
  MatMenuModule,
  MatSidenavModule,
  MatCardModule,
  MatToolbarModule,
  MatProgressSpinnerModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatTooltipModule,
  MatDialogModule
} from '@angular/material';
import { MatDatetimepickerModule, MatNativeDatetimeModule } from '@mat-datetimepicker/core';
import { SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { NgxAaaDatepickerModule } from 'ngx-aaa-datepicker';
import { NgxAutoDisableFormsModule } from 'ngx-auto-disable-forms';
import { YellowsheetCreateComponent } from './components/yellowsheets/yellowsheet-create/yellowsheet-create.component';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { GoogleMapService } from './services/google-maps/google-map.service';


@NgModule({
  declarations: [
    AppComponent,
    JobListComponent,
    JobDetailComponent,
    UserListComponent,
    UserDetailComponent,
    MaterialListComponent,
    MaterialDetailComponent,
    EquipmentListComponent,
    EquipmentDetailComponent,
    TimesheetDetailComponent,
    TimesheetListComponent,
    PayrollDetailComponent,
    HeaderComponent,
    SideNavComponent,
    YellowsheetDetailComponent,
    LoginComponent,
    LoginLayoutComponent,
    HomeLayoutComponent,
    UserCreateComponent,
    YellowsheetListComponent,
    YellowsheetCreateComponent,
    ConfirmationDialogComponent,
    PayrollListComponent,
    TimesheetApprovalListComponent,
    WhosWorkingComponent,    
    GoogleMapComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSelectModule,
    MatOptionModule,
    MatCardModule,
    MatInputModule,
    MatAutocompleteModule,
    MatGridListModule,
    MatSortModule,
    MatMenuModule,
    MatIconModule,
    MatDialogModule,
    MatDatepickerModule,
    MatListModule,
    MatSidenavModule,
    MatToolbarModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatDatetimepickerModule,
    MatProgressSpinnerModule,
    NgxAutoDisableFormsModule,
    MatTooltipModule,
    MatNativeDatetimeModule,
    NgxAaaDatepickerModule,
    SatDatepickerModule,
    SatNativeDateModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  entryComponents: [
    TimesheetDetailComponent,
    PayrollDetailComponent,
    YellowsheetCreateComponent,
    ConfirmationDialogComponent,
    GoogleMapComponent
  ],
  providers: [
    AuthGuard,
    AdminGuard,
    FormanGuard,
    AuthService,
    TimesheetService,
    JobService,
    JobClassService,
    FirestoreService,
    ServiceService,
    EquipmentService,
    PayrollService,
    MaterialService,
    GoogleMapService,
    {
      provide: SETTINGS,
      useValue: environment.emulator ? {
        host: 'localhost:8080',
        ssl: false
      } : undefined
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
