<mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search Material">
</mat-form-field>



<div class="mat-elevation-z8">
    <table class="full-width" mat-table [dataSource]=equipment matSort>
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
            <td mat-cell *matCellDef="let equipment">
                <a routerLink="/equipmentdetail/{{ equipment.id }}">{{equipment.name}}</a>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="25" [pageSizeOptions]="[15, 25, 50]"></mat-paginator>
</div>
<div class="fab-container">
    <button mat-mini-fab [routerLink]="['/newequipment']" color="primary">
<mat-icon aria-label="New Equipment">add</mat-icon>
</button>
</div>