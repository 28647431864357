import { Injectable } from '@angular/core';
import { User } from 'src/app/models/classes/user.class';
import { Observable, of } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';
import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(public db: FirestoreService, private functions: AngularFireFunctions) {}

  getUsers(showAllUsers : boolean): Observable<User[]> {
    if(showAllUsers)
      return this.getAllUsers();
    else
      return this.getActiveUsers();

  }
  
  getAllUsers(): Observable<User[]> {
    return this.db.colWithIds$('users', ref => ref.orderBy('firstName', 'asc'));
  }

  getActiveUsers(): Observable<User[]> {
    return this.db.colWithIds$('users', ref => ref.where('isActive', '==', true));
  }

  async createUser(user: User): Promise<void> {
    return this.functions.httpsCallable('createUser')(user).toPromise();
  }

  async updateUser(userId: string, user: User): Promise<void> {
    const updatedUser = user;
    updatedUser.id = userId;
    return this.functions.httpsCallable('updateUser')(updatedUser).toPromise();
  }

  async changeUserPassword(userId: string, password: string): Promise<void> {
    const updatedUser = new User();
    updatedUser.id = userId;
    updatedUser.password = password;
    return this.functions.httpsCallable('changeUserPassword')(updatedUser).toPromise();
  }

  getUser(userId: string): Observable<User> {
    if (userId === undefined) {
      return of(new User()); // This is a new User
    }
    return this.db.doc$('users/' + userId);
  }

  getForemanUsers(): Observable<User[]> {
    return this.db.colWithIds$('users', ref => ref.where('role', '==', 'Foreman'));
  }
}
