import { Equipment } from './../../models/classes/equipment.class';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class EquipmentService {

  constructor(
    public db: FirestoreService,
  ) {
  }

  getEquipment(): Observable<Equipment[]> {
      return this.db.colWithIds$('equipment');
  }

  getEquipmentDetail(equipmentId: string) {
    if (equipmentId === undefined) {
      return of(new Equipment()); // This is a new Job
    }
    return this.db.doc$('equipment/' + equipmentId);
  }

  async updateEquipment(equipmentId: string, equipment: Equipment): Promise<void> {
    if (equipmentId === undefined) {
      equipmentId = this.db.createId();
    }
    equipment.id = equipmentId;
    return this.db.upsert('equipment/' + equipmentId, equipment);
  }

  getJobEquipment(jobId: string): Observable<Equipment[]> {
    return this.db.colWithIds$('jobs/' + jobId + '/equipment');
  }

  updateJobEquipment(jobId: string, equipment: Equipment[]) {
    //
  }
}
