import { IEquipment } from './../../../models/interfaces/equipment.interface';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { EquipmentService } from 'src/app/services/equipment/equipment.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss']
})

export class EquipmentListComponent implements AfterViewInit {

  displayedColumns: string[] = ['name'];
  equipment: MatTableDataSource<IEquipment>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private equipmentService: EquipmentService
  ) { }

  ngAfterViewInit() {
    this.getEquipment();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.equipment.filter = filterValue;
  }

  getEquipment() {
    this.equipmentService.getEquipment().subscribe(equipment => {
      this.equipment = new MatTableDataSource(equipment);
      this.equipment.paginator = this.paginator;
      this.equipment.sort = this.sort;
    });
  }

}
