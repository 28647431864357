<div class="mb-20" flFlex flexLayout="row" flexLayout.lt-md="column">
    <div fxFlex>
        <mat-form-field>
            <input matInput type="text" [(ngModel)]="searchText" (keyup)="applyFilter($event.target.value)" placeholder="Search Jobs" />
        </mat-form-field>
        <mat-checkbox class="pl10" [(ngModel)]="showAllJobs" (change)="getJobs()" (click)="searchText=''">Show All Jobs?</mat-checkbox>

        <div class="mat-elevation-z8">
            <table class="full-width" mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc">
                            
                <ng-container matColumnDef="isActive">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Active </th>
                    <td mat-cell *matCellDef="let job">                
                        <mat-icon *ngIf="job.isActive" [color]="(job.qbListId !== '' && job.latitude !== undefined) ? 'primary' : 'warn'">check_circle</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                    <td mat-cell *matCellDef="let job">
                        <a routerLink="/jobdetail/{{ job.id }}">{{ job.name }}</a>
                    </td>
                </ng-container>            

                <ng-container matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer Name</th>
                    <td mat-cell *matCellDef="let job">{{ job.customerName }}</td>
                </ng-container>                
                
                <ng-container matColumnDef="addressLine1">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                    <td mat-cell *matCellDef="let job">{{ job.addressLine1 }}</td>
                </ng-container>

                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>City</th>
                    <td mat-cell *matCellDef="let job">{{ job.city }}</td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
                    <td mat-cell *matCellDef="let job">{{ job.state }}</td>
                </ng-container>                

                <ng-container matColumnDef="foreman">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Foreman</th>
                    <td mat-cell *matCellDef="let job"><span *ngIf="job.foreman.hasOwnProperty('lastName')">{{job.foreman.lastName}}, {{job.foreman.firstName}}</span></td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>

            <mat-paginator [pageSize]="100" [pageSizeOptions]="[15, 25, 50, 100]"></mat-paginator>
        </div>
    </div>
</div>
<div class="fab-container">
    <button mat-mini-fab [routerLink]="['/newjob']" color="primary">
    <mat-icon aria-label="New Job">add</mat-icon>
  </button>
</div>