<div class="mb20" flFlex flexLayout="row" flexLayout.lt-md="column">
    <div fxFlex>
        <form>
            <mat-form-field class="pr20">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search By Name" />
            </mat-form-field>
            <div class="week-picker">
                <a (click)="gotoWeek(-1)">
                    <mat-icon>chevron_left</mat-icon>
                </a>
                <span>{{dateRange}}</span>
                <a (click)="gotoWeek(1)">
                    <mat-icon>chevron_right</mat-icon>
                </a>
            </div>

        </form>
        <div class="mat-elevation-z8">
            <table class="full-width" mat-table [dataSource]=payrollItems matSort>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                    <td mat-cell *matCellDef="let payrollItem">
                        <a (click)="openPayroll(payrollItem)">{{payrollItem.user.firstName}} {{payrollItem.user.lastName}}</a>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let payrollItem">
                        <mat-icon *ngIf="showStatus(payrollItem)" [matTooltip]="getStatusTooltip(payrollItem)" [ngStyle]="{'color' : getStatusColor(payrollItem)}">{{getStatusIcon(payrollItem)}}</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="jobName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Job </th>
                    <td mat-cell *matCellDef="let payrollItem">{{payrollItem.job?.name}}</td>
                </ng-container>

                <ng-container matColumnDef="selectedService">
                    <th mat-header-cell *matHeaderCellDef> Service </th>
                    <td mat-cell *matCellDef="let payrollItem">{{payrollItem.selectedService?.name}}</td>
                </ng-container>

                <ng-container matColumnDef="totalHours">
                    <th mat-header-cell *matHeaderCellDef> Hours </th>
                    <td mat-cell *matCellDef="let payrollItem">
                        <div class="hours-icon">
                            <mat-icon [matTooltip]="getPayTypeTooltip(payrollItem)" [ngStyle]="{'color' : getPayTypeColor(payrollItem)}">{{getPayTypeIcon(payrollItem)}}</mat-icon>
                            {{getDailyTotal(payrollItem)}}
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let payrollItem">
                        <div>{{payrollItem.clockIn.toDate() | date:'MM/dd/yyyy':'UTC'}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="clockIn">
                    <th mat-header-cell *matHeaderCellDef> Clock In / Clock Out </th>
                    <td mat-cell *matCellDef="let payrollItem">
                        <div>{{payrollItem.clockIn.toDate() | date: 'h:mmaaaaa'}} - {{payrollItem.clockOut ? (payrollItem.clockOut.toDate() | date: 'h:mmaaaaa') : '?'}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef> Notes </th>
                    <td mat-cell *matCellDef="let payrollItem">{{payrollItem.notes}}</td>
                </ng-container>

                <!-- Default Table lines -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Group header cells-->
                <ng-container matColumnDef="groupExpander">
                    <td colspan="999" mat-cell *matCellDef="let group">
                        <div fxFill fxLayoutAlign="center center">
                            <mat-icon *ngIf="group.expanded">expand_more</mat-icon>
                            <mat-icon *ngIf="!group.expanded">expand_less</mat-icon>
                            <span class="group-name">{{group.groupName}}</span>
                            <span class="group-total">(CLOCKED : {{formatHours(group.rawTime)}} -- REG: {{formatHours(group.regularTime)}} / OTP: {{formatHours(group.overTime)}})</span>
                            <span *ngIf="showApproveButton(group)" class="group-pending"><a (click)="approvePayrollItems(group.value)" ><mat-icon>open_in_browser</mat-icon>Approve All</a></span>
                            <span *ngIf="showPendingQuickbooks(group)" class="group-pendingquickbooks"><mat-icon>open_in_browser</mat-icon>Pending Export</span>
                            <span *ngIf="showSubmittedToQuickbooks(group)" class="group-submitted"><mat-icon>done</mat-icon>Processed</span>
                        </div>
                    </td>
                </ng-container>

                <!-- Group line -->
                <tr mat-row class="clickable" *matRowDef="let row; columns: ['groupExpander']; when: isGroup" (click)="expandGroup(row)"></tr>


            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
        </div>

    </div>
</div>