import { AfterViewInit, Component, Input, ViewChild, EventEmitter, Output, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { ITimesheet } from 'src/app/models/interfaces/timesheet.interface';
import { TimesheetService } from 'src/app/services/timesheets/timesheet.service';
import { TimesheetDetailComponent } from '../timesheet-detail/timesheet-detail.component';
import { Timesheet } from '../../../models/classes/timesheet.class';
import { PayrollService } from 'src/app/services/payroll/payroll.service';
import { ClockedHourType, TimesheetStatus } from 'src/app/models/enums/timesheet.enum';
import { WorkTime } from 'src/app/utilities/worktime';

@Component({
  selector: 'app-timesheet-approvallist',
  templateUrl: './timesheet-approval-list.component.html',
  styleUrls: ['./timesheet-approval-list.component.scss']
})

export class TimesheetApprovalListComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  today = moment();
  fromDate: string = this.today.startOf('week').toISOString();
  toDate: string = this.today.endOf('week').toISOString();
  dateRange = this.today.startOf('week').format('L');

  timesheets: MatTableDataSource<ITimesheet>;
  displayedColumns: string[] = ['user',
                                'status',
                                'jobName',
                                'selectedService',
                                'date',
                                'totalHours',
                                'clockIn',
                                'notes'];
  groupingColumn: string;
  expandedGroups = [];
  initialData: any [];

  showOverTime = false;

  submittedToPayroll = false;

  constructor(
    private timesheetService: TimesheetService,
    private payrollService: PayrollService,
    private dialog: MatDialog
  ) {
  }

  ngAfterViewInit() {
    this.getTimesheets();
  }

  gotoWeek(week: number) {
    this.fromDate = moment(this.fromDate).add(week, 'week').toISOString();
    this.toDate =  moment(this.toDate).add(week, 'week').toISOString();
    this.dateRange = moment(this.fromDate).format('L');
    this.getTimesheets();
  }

  applyFilter(filterValue: string) {
    this.timesheets.filter = filterValue.trim().toLowerCase();
  }

  getTimesheets() {
    this.timesheetService.getSubmittedTimesheetsByDateRange(this.fromDate, this.toDate).subscribe(timesheets => {

      this.initialData = timesheets;
      this.buildDataSource();
      this.timesheets.sort = this.sort;
      this.timesheets.paginator = this.paginator;

      /* configure filter */
      this.timesheets.filterPredicate = (timesheet: Timesheet, filterValue: string) => {
        return timesheet.user.firstName.trim().toLowerCase().indexOf(filterValue) !== -1
          || timesheet.user.lastName.trim().toLowerCase().indexOf(filterValue) !== -1;
      };
    });
  }

  getStatusIcon(status: string) {
    let icon = 'history';
    switch (status) {
      case 'Submitted': {
        icon = 'alarm';
        break;
      }
      case 'Approved': {
        icon = 'alarm';
        break;
      }
      case 'Processed': {
        icon = 'settings_backup_restore';
        break;
      }
      case 'Rejected': {
        icon = 'alarm_off';
        break;
      }
    }
    return icon;
  }

  getStatusColor(status: string) {
    let color = '#0D47A1';
    switch (status) {
      case 'Approved': {
        color = '#008b00';
        break;
      }
      case 'Processed': {
        color = '#008b00';
        break;
      }
      case 'Rejected': {
        color = '#BF360C';
        break;
      }
    }
    return color;
  }

  getDailyTotal(timesheet: ITimesheet): string {
    const start = moment(timesheet.clockIn.toDate());
    const clockOut = timesheet.clockOut ? timesheet.clockOut.toDate() : new Date();
    const end = moment(clockOut);
    const duration = moment.duration(end.diff(start));
    const hours = parseInt(String(duration.asHours()), 10);
    const minutes = parseInt(String(duration.asMinutes()), 10) - hours * 60;
    return `${hours}h ${minutes}m`;
  }

  getTotalWorkDayTimeInMS(timesheet: ITimesheet): number {
    let duration: moment.Duration;

    // Calculate the running total (in milliseconds) for this timesheet
    const start = moment(timesheet.clockIn.toDate());
    const clockOut = timesheet.clockOut ? timesheet.clockOut.toDate() : new Date();
    const end = moment(clockOut);
    duration = moment.duration(end.diff(start));
    return duration.asMilliseconds();
  }

  formatHours(timeInMS: number) {
    const minutes = moment.duration(timeInMS).minutes();
    const hours = Math.trunc(moment.duration(timeInMS).asHours());
    return `${hours}h ${minutes}m`;
  }

  openTimesheet(timesheet: ITimesheet) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      timesheet
    };
    this.dialog.open(TimesheetDetailComponent, dialogConfig);
  }

  buildDataSource() {
    // Sort by user's first name
    const sortedTimesheets = this.initialData.sort((a: Timesheet, b: Timesheet) => {
      const isAsc = 'asc';
      return this.compare(a.user.firstName, b.user.firstName, isAsc);
    });

    const groupedTimesheets = this.groupByName(sortedTimesheets, this.expandedGroups);
    this.timesheets = new MatTableDataSource(groupedTimesheets);
  }

  private compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getOvertimeHours() {
    return 0;
  }

  groupByName(data: any[], expandedGroups?: any[]) {
    const column = 'user';
    let showGroups = expandedGroups;
    if (!expandedGroups) { showGroups = []; }

    // inline function to reduce groups.
    const customReducer = (accumulator, currentValue) => {
      const currentGroup = currentValue[column].qbListId;
      if (!accumulator[currentGroup]) {
        const expanded = showGroups.some((group) => group.value === currentValue[column].qbListId);
        accumulator[currentGroup] = [{
          groupName: `${currentValue[column].firstName} ${currentValue[column].lastName}`,
          value: currentValue[column].qbListId,
          isGroup: true,
          numOfItems: 0,
          numOfApprovedItems: 0,
          numOfSubmittedItems: 0,
          numOfProcessedItems: 0,
          rawTime: 0,
          totalTime: 0,
          totalLunchBreakTime: 0,
          regularTime: 0,
          overTime: 0,
          expanded: showGroups.length > 0 ? expanded : false
        }];
      }

      WorkTime.getWorkTime(accumulator[currentGroup][0], currentValue, null);

      accumulator[currentGroup][0].numOfItems++;

      if (currentValue.status === TimesheetStatus.APPROVED) {
        accumulator[currentGroup][0].numOfApprovedItems++;
      }
      if (currentValue.status === TimesheetStatus.SUBMITTED) {
        accumulator[currentGroup][0].numOfSubmittedItems++;
      }
      if (currentValue.status === TimesheetStatus.PROCESSED) {
        accumulator[currentGroup][0].numOfProcessedItems++;
      }

      if (accumulator[currentGroup][0].expanded) {
        accumulator[currentGroup].push(currentValue);
      }
      return accumulator;
    };
    // package and group data
    const groups = data.reduce(customReducer, {});
    const groupArray = Object.keys(groups).map(key => groups[key]);
    const flatList = groupArray.reduce((a, c) => {
      return a.concat(c);
    }, []);
    return flatList;
  }

  /**
   * Since groups are on the same level as the data,
   * this function is used by @input(matRowDefWhen)
   */
  isGroup(index, item): boolean {
    return item.isGroup;
  }

  /**
   * Used in the view to collapse a group
   * Effectively removing it from the displayed datasource
   */
  expandGroup(row) {
    if (this.submittedToPayroll === true) {
      this.submittedToPayroll = false;
      return;
    }

    row.expanded = !row.expanded;
    if (row.expanded) {
      this.expandedGroups.push(row);
    } else {
      this.expandedGroups = this.expandedGroups.filter((el) => el.value !== row.value);
    }

    this.buildDataSource();
  }

  submitToPayroll(qbListId) {
    this.submittedToPayroll = true;

    // Split the timesheets
    this.payrollService.createPayrollItemsForUser(qbListId, this.fromDate, this.toDate);
  }

  showSubmitToPayroll(group): boolean {
    return group.numOfApprovedItems > 0;
  }

  showSubmitted(group): boolean {
    return group.numOfProcessedItems === group.numOfItems && group.numOfApprovedItems === 0 && group.numOfSubmittedItems === 0;
  }

  showPending(group): boolean {
    return group.numOfApprovedItems === 0 && group.numOfSubmittedItems > 0;
  }
}
