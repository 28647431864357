import { Material } from './../../models/classes/material.class';
import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {

  constructor(
    public db: FirestoreService,
  ) {}

  getMaterials(): Observable<Material[]> {
      return this.db.colWithIds$('materials');
  }

  async updateMaterial(materialId: string, material: Material): Promise<void> {
    if (materialId === undefined) {
      materialId = this.db.createId();
    }
    material.id = materialId;
    return this.db.upsert('materials/' + materialId, material);
  }

  getMaterial(materialId: string) {
    if (materialId === undefined) {
      return of(new Material()); // This is a new Job
    }
    return this.db.doc$('materials/' + materialId);
  }

  async updateJobMaterial(jobId: string, material: Material): Promise<void> {
      return this.db.upsertSubcollectionDoc('jobs/' + jobId + '/materials/', material.id, material);
  }
}
