import { IClockedType } from '../interfaces/clocked-type.interface';
import { ClockedHourType } from '../enums/timesheet.enum';

export class ClockedType implements IClockedType {
    hourType: ClockedHourType;

    constructor(hourType: ClockedHourType) {
        this.hourType = hourType;
    }
}
