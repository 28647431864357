
import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Yellowsheet } from 'src/app/models/classes/yellowsheets.class';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { YellowsheetService } from 'src/app/services/yellowsheets/yellowsheet.service';
import { tap } from 'rxjs/operators';
import { Equipment } from 'src/app/models/classes/equipment.class';
import { Material } from 'src/app/models/classes/material.class';
import { MaterialService } from 'src/app/services/materials/material.service';
import { EquipmentService } from 'src/app/services/equipment/equipment.service';
import { Timesheet } from 'src/app/models/classes/timesheet.class';
import * as firebase from 'firebase';
import { MatDialogConfig, MatDialog } from '@angular/material';
import { TimesheetDetailComponent } from '../../timesheets/timesheet-detail/timesheet-detail.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { User } from 'src/app/models/classes/user.class';

@Component({
    selector: 'app-yellowsheet-detail',
    templateUrl: './yellowsheet-detail.component.html',
    styleUrls: ['./yellowsheet-detail.component.scss']
})
export class YellowsheetDetailComponent implements OnInit {
    public subs: Subscription[] = [];
    public yellowsheetForm: FormGroup;
    public yellowsheet$: Observable<Yellowsheet>;
    public materialList: Material[];
    public equipmentList: Equipment[];
    public jobId: string;
    public yellowsheetDate: Date;
    public yellowsheetId: string;
    public jobName: string;
    public user$: Observable<User>;
    public isAdmin: boolean;

    constructor(
        private fb: FormBuilder,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private dialog: MatDialog,
        public authService: AuthService,
        private materialService: MaterialService,
        private equipmentService: EquipmentService,
        private yellowsheetService: YellowsheetService
    ) { }

    ngOnInit() {
        this.getUserRole();
        this.buildForm();
        this.getYellowsheet();
        this.getMaterialList();
        this.getEquipmentList();
    }

    buildForm(): void {
        this.yellowsheetForm = this.fb.group({
            scopeOfWorkCompleted: '',
            otherNotes: '',
            materials: this.fb.array([]),
            equipment: this.fb.array([])
        });
    }

    getYellowsheet(): void {
        this.subs.push(
            this.activeRoute.params.subscribe(params => {
                this.jobId = params.jobId;
                this.yellowsheetId = params.yellowsheetId;
                this.yellowsheet$ = this.yellowsheetService.getYellowsheet(this.yellowsheetId).pipe(
                    tap(yellowsheet => {
                        this.yellowsheetForm.patchValue(yellowsheet);
                        this.jobName = yellowsheet.jobName;
                        this.yellowsheetDate = new Date(yellowsheet.date);
                        this.populateMaterials(yellowsheet.materials);
                        this.populateEquipment(yellowsheet.equipment);
                    })
                );
            })
        );
    }

    compareIds(id1: any, id2: any): boolean {
        const a1 = id1.id;
        const a2 = id2.id;
        return a1 === a2;
    }

    /*
    * Materials
    */
    getMaterialList(): void {
        this.materialService.getMaterials().subscribe(materials => {
            this.materialList = materials;
        });
    }

    populateMaterials(materials: Material[]) {
        if (materials === undefined) { return; }
        materials.forEach(material => {
            (this.yellowsheetForm.get('materials') as FormArray).push(this.fb.group({
                id: material.id,
                delivered: material.delivered,
                installed: material.installed
            }));
        });
    }

    get material(): FormGroup {
        return this.fb.group({
            id: ['', Validators.required],
            delivered: ['', Validators.required],
            installed: ['', Validators.required]
        });
    }

    addMaterial(): void {
        (this.yellowsheetForm.get('materials') as FormArray).push(this.material);
    }

    deleteMaterial(i: number): void {
        (this.yellowsheetForm.get('materials') as FormArray).removeAt(i);
    }

    /*
    * Equipment
    */
    getEquipmentList(): void {
        this.equipmentService.getEquipment().subscribe(equipment => {
            this.equipmentList = equipment;
        });
    }

    populateEquipment(equipment: Equipment[]) {
        if (equipment === undefined) { return; }
        equipment.forEach(equip => {
            (this.yellowsheetForm.get('equipment') as FormArray).push(this.fb.group({
                id: equip.id,
                hours: equip.hours
            }));
        });
    }

    get equipment(): FormGroup {
        return this.fb.group({
            id: ['', Validators.required],
            hours: ['', Validators.required]
        });
    }

    addEquipment(): void {
        (this.yellowsheetForm.get('equipment') as FormArray).push(this.equipment);
    }

    deleteEquipment(i: number) {
        (this.yellowsheetForm.get('equipment') as FormArray).removeAt(i);
    }

    updateYellowsheet() {
        this.yellowsheetService.updateYellowsheet(this.yellowsheetId, this.yellowsheetForm.value)
            .then(() => {
                this.router.navigateByUrl('jobdetail/' + this.jobId);
            });
    }

    getUserRole() {
        this.user$ = this.authService.user$.pipe(
            tap(user => {
                this.isAdmin = this.authService.isAdmin(user);
            }));
    }

    public canDelete(): boolean {
        return this.isAdmin;
    }

    deleteYellowsheet() {
        this.yellowsheetService.deleteYellowsheet(this.yellowsheetId);
        this.router.navigateByUrl('jobdetail/' + this.jobId);
    }

    addTimesheet() {
        // Create our timesheet object
        const timesheet = new Timesheet(this.jobId, this.jobName, this.yellowsheetId);
        timesheet.clockIn = firebase.firestore.Timestamp.fromDate(new Date(this.yellowsheetDate));
        timesheet.clockOut = firebase.firestore.Timestamp.fromDate(new Date(this.yellowsheetDate));

        // Send the timesheet to the dialog
        const dialogConfig = new MatDialogConfig();
        dialogConfig.data = {
            timesheet
        };
        this.dialog.open(TimesheetDetailComponent, dialogConfig);
    }

}
