
import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { Observable } from 'rxjs';
import { Timesheet } from 'src/app/models/classes/timesheet.class';
import { Job } from 'src/app/models/classes/job.class';
import { } from 'googlemaps';
import { JobService } from '../jobs/job.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapService {

  constructor(
    private jobService: JobService,
  ) {
  }

  updateGeoCoordinates(job: Job) {
    const geocoder = new google.maps.Geocoder();
    const address = `${job.addressLine1}, ${job.city}, ${job.state} ${job.zip}`
    geocoder.geocode({
      address: address
    }, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        job.longitude = results[0].geometry.location.lng();
        job.latitude =results[0].geometry.location.lat()
        this.jobService.updateJob(job.id, job)
            .then(() => {
                return 'success'
            });
        
      } else {
        return `Geocode was not successful for the following reason: ${status}`
      }
    });

  }

  checkTimesheetAgainstGeoFence(timesheet: Timesheet, job: Job, radius: number) {
    const startLatLng = new google.maps.LatLng(timesheet.clockInLongitude, timesheet.clockInLatitude) // User Location
    const geofenceLatLng = new google.maps.LatLng(job.latitude, job.longitude) // Center of geofence
    const distanceInMeters = google.maps.geometry.spherical.computeDistanceBetween(startLatLng, geofenceLatLng)

    return (distanceInMeters < radius);
  }

}
