import * as moment from 'moment';
import { ClockedHourType } from '../models/enums/timesheet.enum';
import { ITimesheet } from '../models/interfaces/timesheet.interface';

export class WorkTime {
    private static halfHourInMS = .5 * 3600000;
    private static eightHoursInMS = 8 * 3600000;
    private static fortyHoursInMS = 40 * 3600000;

    public static getWorkTime(accumulator: any, timeSheet: ITimesheet, hourType: ClockedHourType | null) {
      console.log(`hourType: ${hourType}`);

      // Keep a running count of the total workday hours
      const totalWorkDayTimeInMS = this.getTotalWorkDayTimeInMS(timeSheet);
      accumulator.rawTime += totalWorkDayTimeInMS;

      let lunchBreakTimeInMS = 0;
      // Calculate the Lunch Break time for the current day
      if (hourType) {
        lunchBreakTimeInMS = hourType === ClockedHourType.LUNCH_BREAK ? this.halfHourInMS : 0;
        console.log(`lunchBreakTimeInMS: ${lunchBreakTimeInMS}`);
      } else {
        lunchBreakTimeInMS = totalWorkDayTimeInMS > this.eightHoursInMS ? this.halfHourInMS : 0;
      }
      accumulator.totalLunchBreakTime += lunchBreakTimeInMS;

      // Calculate the TotalTime worked (which excludes the lunch break)
      accumulator.totalTime += totalWorkDayTimeInMS - lunchBreakTimeInMS;

      // Only add in the OT (OverTime) hours if the total clocked hours exceeds 40 hours
      if (accumulator.totalTime > this.fortyHoursInMS) {
        accumulator.regularTime = this.fortyHoursInMS;  // we fix the regular time to be 40 hours since we are now in overtime

        const overTimeInMS = accumulator.totalTime - accumulator.regularTime;
        if (overTimeInMS > 0) {
          accumulator.overTime = overTimeInMS;
        }
      } else {
        accumulator.regularTime += totalWorkDayTimeInMS - lunchBreakTimeInMS;
      }
    }

    private static getTotalWorkDayTimeInMS(timeSheet: ITimesheet): number {
        let duration: moment.Duration;

        // Calculate the running total (in milliseconds) for this timesheet
        const start = moment(timeSheet.clockIn.toDate());
        const clockOut = timeSheet.clockOut ? timeSheet.clockOut.toDate() : new Date();
        const end = moment(clockOut);
        duration = moment.duration(end.diff(start));
        return duration.asMilliseconds();
    }
}
