import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-yellowsheet-create',
  templateUrl: './yellowsheet-create.component.html',
  styleUrls: ['./yellowsheet-create.component.scss']
})
export class YellowsheetCreateComponent implements OnInit {

  yellowsheetDate = new FormControl(new Date());

  constructor( ) { }

  ngOnInit() {
  }
}
