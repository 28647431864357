<div class="mb20" flFlex flexLayout="row" flexLayout.lt-md="column">
    <div fxFlex>
        <form>
            <mat-form-field class="pr20">
                <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search By Name" />
            </mat-form-field>

            <div class="week-picker">
                <a (click)="gotoWeek(-1)">
                    <mat-icon>chevron_left</mat-icon>
                </a>
                <span>{{dateRange}}</span>
                <a (click)="gotoWeek(1)">
                    <mat-icon>chevron_right</mat-icon>
                </a>
            </div>
        </form>
        <div class="mat-elevation-z8">
            <table class="full-width" mat-table [dataSource]=timesheets>

                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let timesheet"><a (click)="openTimesheet(timesheet)">{{timesheet.user.firstName}} {{timesheet.user.lastName}}</a></td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let timesheet">
                        <mat-icon matTooltip="Timesheet is {{timesheet.status}}" [ngStyle]="{'color' : getStatusColor(timesheet.status)}">{{getStatusIcon(timesheet.status)}}</mat-icon>
                    </td>
                </ng-container>

                <ng-container matColumnDef="jobName">
                    <th mat-header-cell *matHeaderCellDef> Job </th>
                    <td mat-cell *matCellDef="let timesheet">{{timesheet.job?.name}}</td>
                </ng-container>

                <ng-container matColumnDef="selectedService">
                    <th mat-header-cell *matHeaderCellDef> Service </th>
                    <td mat-cell *matCellDef="let timesheet">{{timesheet.selectedService?.name}}</td>
                </ng-container>

                <ng-container matColumnDef="totalHours">
                    <th mat-header-cell *matHeaderCellDef> Hours </th>
                    <td mat-cell *matCellDef="let timesheet">{{getDailyTotal(timesheet)}}</td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let timesheet">
                        <div>{{timesheet.clockIn.toDate() | date:'MM/dd/yyyy':'UTC'}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="clockIn">
                    <th mat-header-cell *matHeaderCellDef> Clock In / Clock Out </th>
                    <td mat-cell *matCellDef="let timesheet">
                        <div>{{timesheet.clockIn.toDate() | date: 'h:mmaaaaa'}} - {{timesheet.clockOut ? (timesheet.clockOut.toDate() | date: 'h:mmaaaaa') : '?'}}</div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef> Notes </th>
                    <td mat-cell *matCellDef="let timesheet">{{timesheet.notes}}</td>
                </ng-container>

                <!-- Default Table lines -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                <!-- Group header cells-->
                <ng-container matColumnDef="groupExpander">
                    <td colspan="999" mat-cell *matCellDef="let group">
                        <div fxFill fxLayoutAlign="center center">
                            <mat-icon *ngIf="group.expanded">expand_more</mat-icon>
                            <mat-icon *ngIf="!group.expanded">expand_less</mat-icon>
                            <span class="group-name">{{group.groupName}}</span>
                            <span class="group-total">(CLOCKED : {{formatHours(group.rawTime)}} -- REG: {{formatHours(group.regularTime)}} / OTP: {{formatHours(group.overTime)}})</span>
                            <span *ngIf="showSubmitToPayroll(group)" class="group-submittopayroll"><a (click)="submitToPayroll(group.value)"  mat-flat-button><mat-icon>open_in_browser</mat-icon>Submit to Payroll</a></span>
                            <span *ngIf="showSubmitted(group)" class="group-approved"><mat-icon>done</mat-icon>Submitted</span>
                            <span *ngIf="showPending(group)" class="group-pending"><mat-icon>done</mat-icon>Pending</span>
                        </div>
                    </td>
                </ng-container>

                <!-- Group line -->
                <tr mat-row class="clickable" *matRowDef="let row; columns: ['groupExpander']; when: isGroup" (click)="expandGroup(row)"></tr>


            </table>
            <mat-paginator [pageSize]="25" [pageSizeOptions]="[25,50]"></mat-paginator>
        </div>

    </div>
</div>