<div class="login-page">
    <div class="content">
        <form class="login-form" fxFlex>
            <div class="text-center">
                <h2 class="app-name">Paragon Jobs</h2>
            </div>
            <div>
                <div>
                    <mat-form-field class="w-100">
                        <input matInput placeholder="Email" name="email" [(ngModel)]="email" required>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <div>
                    <mat-form-field class="w-100">
                        <input matInput type="password" name="password" placeholder="Password" [(ngModel)]="password" required>
                    </mat-form-field>
                </div>
            </div>
            <div>
                <div>
                    <button mat-raised-button color="primary" class="w-100" (click)="loginUser()">Login</button>
                </div>
            </div>

        </form>
    </div>
</div>