import { IMaterial } from './../interfaces/material.interface';

export class Material implements IMaterial {
  id: string;
  name: string;
  allocated?: number;
  delivered?: number;
  installed?: number;

  constructor() {
    this.id = undefined;
    this.name = undefined;
    this.allocated = 0;
    this.delivered = 0;
    this.installed = 0;
  }
}
