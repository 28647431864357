import { PayrollItem } from './../../../models/classes/payroll-item.class';
import { AfterViewInit, Component, ViewChild, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { PayrollService } from 'src/app/services/payroll/payroll.service';
import { PayrollDetailComponent } from '../payroll-detail/payroll-detail.component';
import { IPayrollItem } from 'src/app/models/interfaces/payroll-item.interface';
import { PayrollItemStatus } from 'src/app/models/enums/payrollitems.enum';
import { ClockedHourType, ClockedPayType } from 'src/app/models/enums/timesheet.enum';
import { WorkTime } from 'src/app/utilities/worktime';

@Component({
  selector: 'app-payroll',
  templateUrl: './payroll-list.component.html',
  styleUrls: ['./payroll-list.component.scss']
})
export class PayrollListComponent implements OnInit, AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  today = moment();
  fromDate: string = this.today.startOf('week').toISOString();
  toDate: string = this.today.endOf('week').toISOString();
  dateRange = this.today.startOf('week').format('L');

  payrollItems: MatTableDataSource<IPayrollItem>;
  displayedColumns: string[] = ['user',
                                'status',
                                'jobName',
                                'selectedService',
                                'date',
                                'totalHours',
                                'clockIn',
                                'notes'];
  groupingColumn: string;
  expandedGroups = [];
  initialData: any [];

  showOverTime = false;

  approveForPayroll = false;

  constructor(
    private fb: FormBuilder,
    private payrollService: PayrollService,
    private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getPayrollItems();
  }

  gotoWeek(week: number) {
    this.fromDate = moment(this.fromDate).add(week, 'week').toISOString();
    this.toDate =  moment(this.toDate).add(week, 'week').toISOString();
    this.dateRange = moment(this.fromDate).format('L');
    this.getPayrollItems();
  }

  applyFilter(filterValue: string) {
    this.payrollItems.filter = filterValue.trim().toLowerCase();
  }

  getPayrollItems() {
    this.payrollService.getPayrollItems(this.fromDate, this.toDate).subscribe(payrollItems => {
      this.initialData = payrollItems;
      this.buildDataSource();
      this.payrollItems.sort = this.sort;
      this.payrollItems.paginator = this.paginator;

      /* configure filter */
      this.payrollItems.filterPredicate = (payrollItem: IPayrollItem, filterValue: string) => {
        return payrollItem.user.firstName.trim().toLowerCase().indexOf(filterValue) !== -1
          || payrollItem.user.lastName.trim().toLowerCase().indexOf(filterValue) !== -1;
      };
    });
  }

  getStatusTooltip(payrollItem: PayrollItem): string {
    let status = '';
    if (payrollItem.clockedType.hourType === ClockedHourType.LUNCH_BREAK) {
      status = ClockedHourType.LUNCH_BREAK;
    } else {
      status = payrollItem.status;
    }
    return status;
  }

  getPayTypeTooltip(payrollItem: PayrollItem): string {
    let payType = '';
    if (payrollItem.clockedType.hourType === ClockedHourType.LUNCH_BREAK) {
      payType = ClockedHourType.LUNCH_BREAK;
    } else {
      payType = payrollItem.payrollType.payType;
    }
    return payType;
  }

  getStatusIcon(payrollItem: PayrollItem) {
    let icon = 'settings_backup_restore';
    if (payrollItem.clockedType.hourType === ClockedHourType.LUNCH_BREAK) {
      icon = 'lunch_dining';
    } else {
      switch (payrollItem.status) {
        case PayrollItemStatus.PENDING: {
          icon = 'cloud_queue';
          break;
        }
        case PayrollItemStatus.APPROVED: {
          icon = 'cloud_upload';
          break;
        }
        case PayrollItemStatus.QBPROCESSED: {
          icon = 'cloud_done';
          break;
        }
      }
    }
    return icon;
  }

  getPayTypeIcon(payrollItem: PayrollItem) {
    let icon = 'access_time';
    if (payrollItem.clockedType.hourType === ClockedHourType.LUNCH_BREAK) {
      icon = 'restaurant_menu';
    } else {
      switch (payrollItem.payrollType.payType) {
        case ClockedPayType.REGULAR_TIME: {
          icon = 'access_time';
          break;
        }
        case ClockedPayType.OVERTIME: {
          icon = 'access_alarm';
          break;
        }
      }
    }
    return icon;
  }

  showStatus(payrollItem: PayrollItem) {
    return payrollItem.clockedType.hourType !== ClockedHourType.LUNCH_BREAK;
  }

  showPayType(payrollItem: PayrollItem) {
    return payrollItem.clockedType.hourType !== ClockedHourType.LUNCH_BREAK;
  }

  getStatusColor(payrollItem: PayrollItem) {
    let color = '#008b00';
    switch (payrollItem.status) {
      case PayrollItemStatus.PENDING: {
        color = '#0D47A1';
        break;
      }
      case PayrollItemStatus.APPROVED: {
        color = '#ff5722';
        break;
      }
      case PayrollItemStatus.QBPROCESSED: {
        color = '#008b00';
        break;
      }
    }
    return color;
  }

  getPayTypeColor(payrollItem: PayrollItem) {
    let color = '#0D47A1';
    if (payrollItem.clockedType.hourType === ClockedHourType.LUNCH_BREAK) {
      color = '#0D47A1';
    } else {
      switch (payrollItem.payrollType.payType) {
        case ClockedPayType.REGULAR_TIME: {
          color = '#0D47A1';
          break;
        }
        case ClockedPayType.OVERTIME: {
          color = '#FF0000';
          break;
        }
      }
    }
    return color;
  }

  getDailyTotal(payrollItem: IPayrollItem): string {
    const start = moment(payrollItem.clockIn.toDate());
    const clockOut = payrollItem.clockOut ? payrollItem.clockOut.toDate() : new Date();
    const end = moment(clockOut);
    const duration = moment.duration(end.diff(start));
    const hours = parseInt(String(duration.asHours()), 10);
    const minutes = parseInt(String(duration.asMinutes()), 10) - hours * 60;
    return `${hours}h ${minutes}m`;
  }

  getTotalWorkDayTimeInMS(payrollItem: IPayrollItem): number {
    let duration: moment.Duration;

    // Calculate the running total (in milliseconds) for this timesheet
    const start = moment(payrollItem.clockIn.toDate());
    const clockOut = payrollItem.clockOut ? payrollItem.clockOut.toDate() : new Date();
    const end = moment(clockOut);
    duration = moment.duration(end.diff(start));
    return duration.asMilliseconds();
  }

  formatHours(timeInMS: number) {
    const minutes = moment.duration(timeInMS).minutes();
    const hours = Math.trunc(moment.duration(timeInMS).asHours());
    return `${hours}h ${minutes}m`;
  }

  openPayroll(payrollItem: IPayrollItem) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      payroll: payrollItem,
      fromDate: this.fromDate,
      toDate: this.toDate
    };
    this.dialog.open(PayrollDetailComponent, dialogConfig);
  }

  buildDataSource() {
    const groupedPayrollItems = this.groupByName(this.initialData, this.expandedGroups);
    this.payrollItems = new MatTableDataSource(groupedPayrollItems);
  }

  groupByName(data: any[], expandedGroups?: any[]) {
    const column = 'user';
    let showGroups = expandedGroups;
    if (!expandedGroups) { showGroups = []; }

    // inline function to reduce groups.
    const customReducer = (accumulator, currentValue) => {
      const currentGroup = currentValue[column].qbListId;
      if (!accumulator[currentGroup]) {
        const expanded = showGroups.some((group) => group.value === currentValue[column].qbListId);
        accumulator[currentGroup] = [{
          groupName: `${currentValue[column].firstName} ${currentValue[column].lastName}`,
          value: currentValue[column].qbListId,
          isGroup: true,
          numOfItems: 0,
          numOfPendingItems: 0,
          numOfApprovedItems: 0,
          numOfProcessedItems: 0,
          rawTime: 0,
          totalTime: 0,
          totalLunchBreakTime: 0,
          regularTime: 0,
          overTime: 0,
          expanded: showGroups.length > 0 ? expanded : false
        }];
      }

      WorkTime.getWorkTime(accumulator[currentGroup][0], currentValue, currentValue.clockedType.hourType);

      accumulator[currentGroup][0].numOfItems++;

      // Only count non-lunch break hours
      if (currentValue.status === PayrollItemStatus.PENDING && currentValue.clockedType.hourType !== ClockedHourType.LUNCH_BREAK) {
        accumulator[currentGroup][0].numOfPendingItems++;
      }
      if (currentValue.status === PayrollItemStatus.APPROVED) {
        accumulator[currentGroup][0].numOfApprovedItems++;
      }
      if (currentValue.status === PayrollItemStatus.QBPROCESSED) {
        accumulator[currentGroup][0].numOfProcessedItems++;
      }

      if (accumulator[currentGroup][0].expanded) {
        accumulator[currentGroup].push(currentValue);
      }
      return accumulator;
    };
    // package and group data
    const groups = data.reduce(customReducer, {});
    const groupArray = Object.keys(groups).map(key => groups[key]);
    const flatList = groupArray.reduce((a, c) => {
      return a.concat(c);
    }, []);
    return flatList;
  }

  /**
   * Since groups are on the same level as the data,
   * this function is used by @input(matRowDefWhen)
   */
  isGroup(index, item): boolean {
    return item.isGroup;
  }

  /**
   * Used in the view to collapse a group
   * Effectively removing it from the displayed datasource
   */
  expandGroup(row) {
    if (this.approveForPayroll === true) {
      this.approveForPayroll = false;
      return;
    }

    row.expanded = !row.expanded;
    if (row.expanded) {
      this.expandedGroups.push(row);
    } else {
      this.expandedGroups = this.expandedGroups.filter((el) => el.value !== row.value);
    }

    this.buildDataSource();
  }

  showPendingQuickbooks(group): boolean {
    return group.numOfPendingItems === 0 && group.numOfApprovedItems > 0;
  }

  showSubmittedToQuickbooks(group): boolean {
    return group.numOfProcessedItems === group.numOfItems && group.numOfApprovedItems === 0 && group.numOfSubmittedItems === 0;
  }

  showApproveButton(group): boolean {
    return group.numOfPendingItems > 0;
  }

  approvePayrollItems(userId) {
    this.approveForPayroll = true;

    // Split the timesheets
    this.payrollService.approvePayrollItemsForUser(userId, this.fromDate, this.toDate);
  }
}
