import { IYellowsheet } from '../interfaces/yellowsheet.interface';
import { Material } from './material.class';
import { Equipment } from './equipment.class';

export class Yellowsheet implements IYellowsheet {
  public id: string;
  public scopeOfWorkCompleted: string;
  public otherNotes: string;
  public jobId: string;
  public jobName: string;
  public date: number;
  public materials: Material[];
  public equipment: Equipment[];

  constructor(jobId: string, jobName: string, date: Date) {
    this.jobId = jobId;
    this.jobName = jobName;
    this.date =  +new Date(date).setHours(0, 0, 0, 0);
    this.equipment = new Array<Equipment>();
    this.materials = new Array<Material>();
  }
}
