import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Job } from 'src/app/models/classes/job.class';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
    providedIn: 'root'
})
export class JobService {

    constructor(
        public db: FirestoreService,
    ) {
    }

    getJobs(getAllJobs: boolean): Observable<Job[]> {
        if (getAllJobs) {
            return this.getAllJobs();
        } else {
            return this.getActiveJobs();
        }
    }

    // For some reason the sort by 'name' doesn't return anything the first time called, so moving to the MatTable
    getActiveJobs(): Observable<Job[]> {
        return this.db.colWithIds$('jobs', ref => ref.where('isActive', '==', true));
    }

    getAllJobs(): Observable<Job[]> {
        return this.db.colWithIds$('jobs', ref => ref.orderBy('name', 'asc'));
    }

    getJob(jobId: string): Observable<Job> {
        if (jobId === undefined) {
            return of(new Job()); // This is a new Job
        }
        return this.db.doc$('jobs/' + jobId);
    }

    async updateJob(jobId: string, job: Job): Promise<void> {
        if (jobId === undefined) {
            jobId = this.db.createId();
        }
        job.id = jobId; // We need this for our Mobile app
        return this.db.upsert('jobs/' + jobId, job);
    }

    async deleteJob(jobId: string): Promise<void> {
        return this.db.delete('jobs/' + jobId);
    }
}
