import { IMaterial } from './../../../models/interfaces/material.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MaterialService } from 'src/app/services/materials/material.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-material-detail',
  templateUrl: './material-detail.component.html',
  styleUrls: ['./material-detail.component.scss']
})

export class MaterialDetailComponent implements  OnInit, OnDestroy {

  public materialForm: FormGroup;
  public subs: Subscription[] = [];
  public material: IMaterial;
  public materialId: string;
  public materialName: string;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private materialService: MaterialService
  ) {}

  ngOnInit() {
    this.materialForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.getMaterial();
  }

  getMaterial() {
    this.subs.push(
      this.activeRoute.params.subscribe(params => {
        this.materialId = params.materialId;
        this.materialService
          .getMaterial(this.materialId).subscribe((material: IMaterial) => {
              this.materialForm.patchValue(material);
              this.materialName = material.name;
          });
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  updateMaterial() {
      this.materialService.updateMaterial(this.materialId, this.materialForm.value)
      .then(() => {
        this.router.navigateByUrl('materials');
      });
  }

}
