<form class="payroll-detail-form mb-20" [formGroup]="payrollDetailForm" *ngIf="data">
  <button [disabled]="payrollDetailForm.invalid" class="delete" *ngIf="payrollId !== undefined && canDelete()" (click)="deletePayroll()" mat-dialog-close mat-button color="warn"><mat-icon>delete_forever</mat-icon>Delete</button>
  <h1 mat-dialog-title>Payroll Detail</h1>
  <div mat-dialog-content *ngIf="user$ | async;">
      <!-- Payroll ID: {{this.payroll.id}}<br>
      Timesheet ID: {{this.timesheetId}}<br>
      Payroll ID: {{this.payrollId}}<br>
      qbListId: {{this.qbListId}}<br> -->
      <!-- User -->
      <div class="row">
          <div class="column no-padding">
            <mat-form-field class="full-width">
              <input matInput readonly formControlName="user" placeholder="User" [matAutocomplete]="userAutoComplete">
            </mat-form-field>
            <mat-autocomplete #userAutoComplete="matAutocomplete" [displayWith]="displayUsername">
              <mat-option *ngFor="let user of (filteredUsers$ | async)" [value]="user">{{user.firstName}} {{user.lastName}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="payrollDetailForm.get('user').errors && payrollDetailForm.get('user').errors.invalidSelection">
              Invalid User
            </mat-error>
      </div>
      </div>
      <!-- Job -->
      <div class="row">
          <div class="column no-padding">
            <mat-form-field class="full-width">
              <input matInput readonly formControlName="job" placeholder="Job" [matAutocomplete]="autoJob">
            </mat-form-field>
            <mat-autocomplete #autoJob="matAutocomplete" [displayWith]="displayJobName" (optionSelected)="populateServices($event.option.value)">
              <mat-option *ngFor="let job of (filteredJobs$ | async)" [value]="job">{{job.name}}</mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="payrollDetailForm.get('job').errors && payrollDetailForm.get('job').errors.invalidSelection">
              Invalid Job
            </mat-error>
          </div>
      </div>
      <!-- Job Service -->
      <div class="row">
        <div class="column no-padding">
          <mat-form-field>
            <input matInput readonly formControlName="selectedService" placeholder="Job Service">
          </mat-form-field>
        </div>
      </div>
      <!-- hourType/totalhrs -->
      <div class="row">
        <div class="column no-padding">
          <mat-form-field>
            <mat-placeholder>Clocked Type</mat-placeholder>
            <input matInput readonly formControlName="hourType" autocomplete="false">
          </mat-form-field>
        </div>
        <div class="column no-padding">
          <mat-form-field>
            <mat-placeholder>Total Time</mat-placeholder>
            <input matInput readonly formControlName="totalhrs" autocomplete="false">
          </mat-form-field>
        </div>
      </div>

  </div>
  <div mat-dialog-actions>
      <button mat-dialog-close mat-raised-button>Cancel</button>
    </div>
</form>