import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    return this.authService.currentUser.pipe(
      take(1),
      map(user => {
          // console.log('user: ', user);
          return !!user;
      }),
      tap(loggedIn => {
          // console.log('loggedIn: ', loggedIn);
          if (!loggedIn) {
              // console.log('access denied');
              this.router.navigate(['/login']);
          }
      })
  );

  }
}
