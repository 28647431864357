export enum ClockedHourType {
    BEFORE_LUNCH = 'Before Lunch',
    LUNCH_BREAK = 'Lunch Break',
    AFTER_LUNCH = 'After Lunch',
    OVERTIME = '',
    NONE = ''
}

export enum ClockedPayType {
    OVERTIME = 'OVERTIME',
    REGULAR_TIME = 'REGULAR TIME'
}

export enum TimesheetStatus {
    ACTIVE = 'Active',
    APPROVED = 'Approved',
    SUBMITTED = 'Submitted',
    PROCESSED = 'Processed'
}
