import { NgModule } from '@angular/core';
import { RouterModule, Routes, CanActivate } from '@angular/router';

import { YellowsheetDetailComponent } from './components/yellowsheets/yellowsheet-detail/yellowsheet-detail.component';
import { JobDetailComponent } from './components/jobs/job-detail/job-detail.component';
import { JobListComponent } from './components/jobs/job-list/job-list.component';
import { UserListComponent } from './components/users/user-list/user-list.component';
import { UserDetailComponent } from './components/users/user-detail/user-detail.component';
import { LoginComponent } from './components/auth/login/login.component';
import { UserCreateComponent } from './components/users/user-create/user-create.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { FormanGuard } from './guards/forman.guard';
import { LoginLayoutComponent } from './layouts/login/login-layout.component';
import { HomeLayoutComponent } from './layouts/home/home-layout.component';
import { TimesheetListComponent } from './components/timesheets/timesheet-list/timesheet-list.component';
import { MaterialListComponent } from './components/materials/material-list/material-list.component';
import { MaterialDetailComponent } from './components/materials/material-detail/material-detail.component';
import { EquipmentListComponent } from './components/equipment/equipment-list/equipment-list.component';
import { EquipmentDetailComponent } from './components/equipment/equipment-detail/equipment-detail.component';
import { PayrollListComponent } from './components/payroll/payroll-list/payroll-list.component';
import { TimesheetApprovalListComponent } from './components/timesheets/timesheet-approval-list/timesheet-approval-list.component';
import { WhosWorkingComponent } from './components/timesheets/whos-working/whos-working.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'jobs',
        pathMatch: 'full'
      },
      {
        path: 'jobs',
        component: JobListComponent,
        data: {
          breadcrumb: 'Jobs'
        }
      },
      {
        path: 'approvals',
        component: TimesheetApprovalListComponent,
        data: {
          breadcrumb: 'Approvals'
        }
      },
      {
        path: 'payroll',
        component: PayrollListComponent,
        data: {
          breadcrumb: 'Payroll'
        }
      },
      {
        path: 'newjob',
        component: JobDetailComponent,
        data: {
          breadcrumb: 'Job'
        }
      },
      {
        path: 'jobdetail/:jobId',
        component: JobDetailComponent,
        data: {
          breadcrumb: 'Job'
        }
      },
      {
        path: 'jobdetail/:jobId/yellowsheet/',
        component: YellowsheetDetailComponent,
        data: {
          breadcrumb: 'Yellowsheet'
        }
      },
      {
        path: 'jobdetail/:jobId/yellowsheet/:yellowsheetId',
        component: YellowsheetDetailComponent,
        data: {
          breadcrumb: 'Yellowsheet'
        }
      },
      {
        path: 'users',
        component: UserListComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Users'
        }
      },
      {
        path: 'newuser',
        component: UserCreateComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'User'
        }
      },
      {
        path: 'userdetail/:userId',
        component: UserDetailComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'User'
        }
      },
      {
        path: 'materials',
        component: MaterialListComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Materials'
        }
      },
      {
        path: 'newmaterial',
        component: MaterialDetailComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Materials'
        }
      },
      {
        path: 'materialdetail/:materialId',
        component: MaterialDetailComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Materials'
        }
      },
      {
        path: 'equipment',
        component: EquipmentListComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Equipment'
        }
      },
      {
        path: 'newequipment',
        component: EquipmentDetailComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Equipment'
        }
      },
      {
        path: 'equipmentdetail/:equipmentId',
        component: EquipmentDetailComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Equipment'
        }
      },
      {
        path: 'whosworking',
        component: WhosWorkingComponent,
        canActivate: [AdminGuard],
        data: {
          breadcrumb: 'Who\'s Working'
        }
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
    ]
  },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})

export class AppRoutingModule { }
