<form class="yellowsheet-form mb-20" [formGroup]="yellowsheetForm" *ngIf="yellowsheet$ | async;">
    <ol class="breadcrumb">
        <li><a [routerLink]="['/jobs']">Jobs</a></li>
        <li><a [routerLink]="['/jobdetail', jobId]">{{jobName}} </a></li>
        <li class="active">Yellow Sheet Detail</li>
    </ol>
    <div class="content" *ngIf="user$ | async;">
        <div class="row">
            <div class="column no-padding">
                <h2>{{jobName}} ({{yellowsheetDate | date:'MM/dd/yyyy'}})</h2>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <div class="header">
                    <h3 class="title">Timesheets</h3>
                    <!-- Timesheet Add Button -->
                    <div class="action-button" *ngIf="yellowsheetId">
                        <button mat-mini-fab (click)="addTimesheet()" color="primary">
              <mat-icon aria-label="New Timesheet">add</mat-icon>
            </button>
                    </div>
                </div>
                <!-- Timesheet Collection -->
                <app-timesheetlist [jobId]="jobId" [jobName]="jobName" [yellowsheetId]="yellowsheetId" [yellowsheetDate]="yellowsheetDate"></app-timesheetlist>
            </div>
        </div>
        <div class="row">
            <div class="column">
                <mat-form-field>
                    <textarea matInput formControlName="scopeOfWorkCompleted" placeholder="Enter Scope of Work Completed" rows="10"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <!-- Left Column -->
            <div class="column">
                <!-- Yellowsheet Materials -->
                <div class="header">
                    <h3 class="title">Material</h3>
                    <!-- Material Add Button -->
                    <div class="action-button">
                        <button mat-mini-fab (click)="addMaterial()" color="primary">
              <mat-icon aria-label="New Material">add</mat-icon>
            </button>
                    </div>
                </div>
                <!-- Material Collection -->
                <div formArrayName="materials" class="form-array">
                    <div *ngFor="let material of yellowsheetForm.controls.materials.controls; let i=index" [formGroupName]="i">
                        <!-- Material Name Select-->
                        <mat-form-field>
                            <mat-select formControlName="id">
                                <mat-option *ngFor="let material of materialList" [value]="material.id">
                                    {{material.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- Material Delivered Input -->
                        <mat-form-field>
                            <input matInput formControlName="delivered" placeholder="QTY Delivered">
                        </mat-form-field>
                        <!-- Material Installed Input -->
                        <mat-form-field>
                            <input matInput formControlName="installed" placeholder="QTY Installed">
                        </mat-form-field>
                        <!-- Materila Delete -->
                        <a (click)="deleteMaterial(i)">
                            <mat-icon color="warn" aria-label="Delete Material">delete_forever</mat-icon>
                        </a>
                    </div>
                </div>
                <!-- // Material Collection -->
                <!-- // Yellowsheet Materials -->
            </div>
            <!-- // Left Column -->
            <!-- Right Column -->
            <div class="column">
                <!-- Job Equipment -->
                <div class="header">
                    <h3 class="title">Equipment</h3>
                    <!-- Add Equipment Button -->
                    <div class="action-button">
                        <button mat-mini-fab (click)="addEquipment()" color="primary"><mat-icon aria-label="New Equipment">add</mat-icon></button>
                    </div>
                </div>
                <!-- Equipmment Collection -->
                <div class="form-array" formArrayName="equipment">
                    <div *ngFor="let equipment of yellowsheetForm.controls.equipment.controls; let i=index" [formGroupName]="i">
                        <!-- Equipment Name -->
                        <mat-form-field>
                            <mat-select formControlName="id">
                                <mat-option *ngFor="let equipment of equipmentList" [value]="equipment.id">
                                    {{equipment.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <!-- Equipment Allocation -->
                        <mat-form-field>
                            <input matInput formControlName="hours" placeholder="Hours">
                        </mat-form-field>
                        <!-- Delete Equipment -->
                        <a (click)="deleteEquipment(i)">
                            <mat-icon color="warn" aria-label="Delete Equipment">delete_forever</mat-icon>
                        </a>
                    </div>
                </div>
            </div>
            <!-- // Right Column -->
        </div>
        <div class="row">
            <div class="column">
                <mat-form-field>
                    <textarea matInput formControlName="otherNotes" placeholder="Other" rows="5"></textarea>
                </mat-form-field>
            </div>
        </div>
        <div class="row button-row">
            <button routerLink="/jobdetail/{{jobId}}" mat-raised-button>Cancel</button>
            <button type="submit" [disabled]="yellowsheetForm.invalid" (click)="updateYellowsheet()" mat-raised-button color="primary"><mat-icon>done</mat-icon> Save</button>
            <button class="pull-right" *ngIf="canDelete()" (click)="deleteYellowsheet()" mat-raised-button color="warn"><mat-icon>delete_forever</mat-icon> Delete</button>
        </div>
    </div>
    <!--
  <pre>FormData {{ yellowsheetForm.value | json }}</pre>
  -->
</form>