<form class="user-form" [formGroup]="newUserForm" *ngIf="user$ | async;" (ngSubmit)="createUser()">

    <div class="content">
        <div class="row">
            <div class="column no-padding">
                <h2>New User</h2>
            </div>
        </div>
        <div class="row">
            <!-- Left Column -->
            <div class="column">
                <!-- Email -->
                <mat-form-field class="full-width">
                    <input matInput formControlName="email" placeholder="Email" />
                </mat-form-field>
                <!-- Password -->
                <mat-form-field class="full-width">
                    <input matInput type="password" formControlName="password" placeholder="Password" autocomplete="new-password" />
                </mat-form-field>
                <!-- First Name -->
                <mat-form-field class="full-width">
                    <input matInput formControlName="firstName" placeholder="First Name" />
                </mat-form-field>
                <!-- Last Name -->
                <mat-form-field class="full-width">
                    <input matInput formControlName="lastName" placeholder="Last Name" />
                </mat-form-field>
                <!-- Role -->
                <mat-form-field class="full-width">
                    <mat-select formControlName="role">
                        <mat-option *ngFor="let role of (roles | async)" [value]="role">
                            {{role}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row button-row">
            <button type="submit" [disabled]="newUserForm.invalid" mat-raised-button color="primary">Save</button>
            <button routerLink="/jobs" mat-raised-button color="secondary">Cancel</button>
        </div>
    </div>
</form>