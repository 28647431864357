import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Timesheet } from 'src/app/models/classes/timesheet.class';
import { FirestoreService } from '../firestore/firestore.service';
import { TimesheetStatus } from 'src/app/models/enums/timesheet.enum';

@Injectable({
    providedIn: 'root'
})
export class TimesheetService {

    constructor(
        public db: FirestoreService,
    ) {
    }

    getJobTimesheets(jobId: string): Observable<Timesheet[]> {
        if (jobId !== undefined) {
            return this.db.colWithIds$('timesheets', ref => ref.where('job.id', '==', jobId)
            .orderBy('clockIn', 'asc'));
        } else {
          return this.db.colWithIds$('timesheets', ref => ref.orderBy('clockIn', 'asc'));
        }
    }

    getTimesheets(jobId: string, yellowsheetId?: string, fromDate?: string, toDate?: string): Observable<Timesheet[]> {
        if (jobId !== undefined && yellowsheetId !== undefined) {
            return this.db.colWithIds$('timesheets', ref => ref.where('job.id', '==', jobId)
                  .where('yellowsheetId', '==', yellowsheetId).orderBy('clockIn', 'asc'));
        } else if (jobId !== undefined) {
            return this.db.colWithIds$('timesheets', ref => ref.where('job.id', '==', jobId)
                  .orderBy('clockIn', 'asc'));
        } else {
            return this.db.colWithIds$('timesheets', ref => ref.where('clockIn', '>=', new Date(fromDate))
                  .where('clockIn', '<=', new Date(toDate)).orderBy('clockIn', 'asc'));
        }
    }

    getTimesheetsByDateRange(fromDate: string, toDate: string): Observable<Timesheet[]> {
          return this.db.colWithIds$('timesheets', ref => ref.where('clockIn', '>=', new Date(fromDate))
                .where('clockIn', '<=', new Date(toDate)).orderBy('clockIn', 'asc'));
    }

    getSubmittedTimesheetsByDateRange(fromDate: string, toDate: string): Observable<Timesheet[]> {
        return this.db.colWithIds$('timesheets', ref => ref.where('clockIn', '>=', new Date(fromDate))
              .where('clockIn', '<=', new Date(toDate))
              .where('status', 'in', [TimesheetStatus.APPROVED, TimesheetStatus.SUBMITTED, TimesheetStatus.PROCESSED])
              .orderBy('clockIn', 'asc'));
    }

    getTimesheetsByUser(qbListId: string, fromDate: string, toDate: string, status?: string): Observable<Timesheet[]> {
        if (qbListId !== undefined && fromDate !== undefined && toDate !== undefined && status !== undefined) {
            return this.db.colWithIds$('timesheets', ref => ref.where('user.qbListId', '==', qbListId)
                                                                .where('clockIn', '>=', new Date(fromDate)).where('clockIn', '<=', new Date(toDate))
                                                                .where('status', '==', status)
                                                                .orderBy('clockIn', 'asc'));
        } else {
            return this.db.colWithIds$('timesheets', ref => ref.orderBy('clockIn', 'asc'));
        }
    }

    getTimesheet(timesheetId: string): Observable<Timesheet> {
        return this.db.doc$('timesheets/' + timesheetId);
    }

    getWhosWorking() {
/*
        const startOfDay = new Date();
        startOfDay.setUTCHours(0, 0, 0, 0);

        return this.db.colWithIds$('timesheets', ref => ref.where('clockIn', '>=', startOfDay)
        .where('clockOut', '==', null)
        .orderBy('clockIn', 'asc'));
*/
        return this.db.colWithIds$('timesheets', ref => ref.where('clockOut', '==', null)
        .orderBy('clockIn', 'asc'));
    }

    async updateTimesheet(timesheet: Timesheet): Promise<void> {
        if (timesheet.id === undefined) {
            timesheet.id = this.db.createId();
        }
        return this.db.upsert('timesheets/' + timesheet.id, timesheet);
    }

    async deleteTimesheet(timesheetId: string): Promise<void> {
        return this.db.delete('timesheets/' + timesheetId);
    }
    
}
