import { JobClass } from '../../models/classes/job-class.class';
import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobClassService {

  constructor(
    public db: FirestoreService,
  ) {
  }

  getClasses(): Observable<JobClass[]> {
      return this.db.colWithIds$('classes');
  }

}
