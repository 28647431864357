export class QBTimeData {
    regularTimeName: string;
    regularTimeId: string;
    overTimeName: string;
    overTimeId: string;

    constructor(regularTimeName, regularTimeId, overTimeName, overTimeId) {
        this.regularTimeName = regularTimeName;
        this.regularTimeId = regularTimeId;
        this.overTimeName = overTimeName;
        this.overTimeId = overTimeId;
    }
}
