import { IMaterial } from './../../../models/interfaces/material.interface';
import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MaterialService } from 'src/app/services/materials/material.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';


@Component({
  selector: 'app-material-list',
  templateUrl: './material-list.component.html',
  styleUrls: ['./material-list.component.scss']
})
export class MaterialListComponent implements AfterViewInit {


  displayedColumns: string[] = ['name'];
  materials: MatTableDataSource<IMaterial>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private materialService: MaterialService
  ) { }

  ngAfterViewInit() {
    this.getMaterials();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.materials.filter = filterValue;
  }

  getMaterials() {
    this.materialService.getMaterials().subscribe(materials => {
      this.materials = new MatTableDataSource(materials);
      this.materials.paginator = this.paginator;
      this.materials.sort = this.sort;
    });
  }

}
