import { ITimesheet } from '../interfaces/timesheet.interface';
import { Job } from './job.class';
import { IUser } from '../interfaces/user.interface';
import { Service } from './service.class';
import { TimesheetStatus } from '../enums/timesheet.enum';

export class Timesheet implements ITimesheet {
  public id: string;
  public job: Job;
  public selectedService: Service;
  public clockInLatitude: number;
  public clockInLongitude: number;
  public clockIn: firebase.firestore.Timestamp;
  public clockOut: firebase.firestore.Timestamp;
  public notes: string;
  public status: string;
  public user: IUser;
  public yellowsheetId: string;
  public processed: boolean;
  public noLunchBreak: boolean;

  constructor(jobId: string, jobName: string, yellowsheetId: string) {
    this.id = undefined;
    this.job = new Job();
    this.job.id = jobId;
    this.job.name = jobName;
    this.user = undefined;
    this.notes = undefined;
    this.clockIn = undefined;
    this.clockOut = undefined;
    this.clockInLongitude = 0.0;
    this.clockInLatitude = 0.0;
    this.status = TimesheetStatus.ACTIVE;
    this.yellowsheetId = yellowsheetId;
    this.processed = false;
    this.noLunchBreak = false;
  }
}
