import { IEquipment } from './../interfaces/equipment.interface';

export class Equipment implements IEquipment {
  id: string;
  name: string;
  allocated?: number;
  hours?: number;

  constructor() {
    this.id = undefined;
    this.name = undefined;
    this.allocated = 0;
    this.hours = 0;
  }
}
