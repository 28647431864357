<mat-toolbar color="primary" class="fix-nav" *ngIf="user$ | async;">
    <button type="button" mat-icon-button class="visible-md" (click)="toggleSidebar()">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
    </button>
    <div class="nav-brand">
        Paragon Jobs
    </div>
    <span class="nav-spacer"></span>
    <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile">
        <mat-icon>account_circle</mat-icon>
    </button> {{userName}}
    <mat-menu #profile="matMenu">
        <button mat-menu-item (click)="logout()">
            <mat-icon>person</mat-icon>
            <span>Logout</span>
        </button>
    </mat-menu>
</mat-toolbar>