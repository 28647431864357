import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ITimesheet } from 'src/app/models/interfaces/timesheet.interface';
import { TimesheetService } from 'src/app/services/timesheets/timesheet.service';
import { TimesheetDetailComponent } from '../timesheet-detail/timesheet-detail.component';
import { Timesheet } from './../../../models/classes/timesheet.class';
import { PayrollService } from 'src/app/services/payroll/payroll.service';
import * as moment from 'moment';

@Component({
  selector: 'app-timesheetlist',
  templateUrl: './timesheet-list.component.html',
  styleUrls: ['./timesheet-list.component.scss']
})

export class TimesheetListComponent implements AfterViewInit {

  @Input() jobId: string;
  @Input() jobName: string;
  @Input() yellowsheetId: string;
  @Input() yellowsheetDate: number;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  form: FormGroup;
  timesheets: MatTableDataSource<ITimesheet>;
  displayedColumns: string[] = ['user',
                                'status',
                                'jobName',
                                'selectedService',
                                'date',
                                'totalHours',
                                'clockIn',
                                'notes'];
  groupingColumn: string;
  expandedGroups = [];
  initialData: any [];

  fortyHoursInMS = 40 * 3600000;

  constructor(
    private fb: FormBuilder,
    private timesheetService: TimesheetService,
    private payrollService: PayrollService,
    private dialog: MatDialog
  ) {
  }


  ngAfterViewInit() {
    this.getTimesheets();
  }

  applyFilter(filterValue: string) {
    this.timesheets.filter = filterValue.trim().toLowerCase();
  }

  getTimesheets() {
    this.timesheetService.getTimesheets(this.jobId, this.yellowsheetId).subscribe(timesheets => {

      this.timesheets = new MatTableDataSource(timesheets);
      this.timesheets.sort = this.sort;
      this.timesheets.paginator = this.paginator;

      /* configure filter */
      this.timesheets.filterPredicate = (timesheet: Timesheet, filterValue: string) => {
        return timesheet.user.firstName.trim().toLowerCase().indexOf(filterValue) !== -1
          || timesheet.user.lastName.trim().toLowerCase().indexOf(filterValue) !== -1;
      };
    });
  }

  getStatusIcon(status: string) {
    let icon = 'history';
    switch (status) {
      case 'Submitted': {
        icon = 'alarm';
        break;
      }
      case 'Approved': {
        icon = 'alarm';
        break;
      }
      case 'Processed': {
        icon = 'settings_backup_restore';
        break;
      }
      case 'Rejected': {
        icon = 'alarm_off';
        break;
      }
    }
    return icon;
  }

  getStatusColor(status: string) {
    let color = '#0D47A1';
    switch (status) {
      case 'Approved': {
        color = '#008b00';
        break;
      }
      case 'Processed': {
        color = '#008b00';
        break;
      }
      case 'Rejected': {
        color = '#BF360C';
        break;
      }
    }
    return color;
  }

  getDailyTotal(timesheet: ITimesheet): string {
    const start = moment(timesheet.clockIn.toDate());
    const clockOut = timesheet.clockOut ? timesheet.clockOut.toDate() : new Date();
    const end = moment(clockOut);
    const duration = moment.duration(end.diff(start));
    const hours = parseInt(String(duration.asHours()), 10);
    const minutes = parseInt(String(duration.asMinutes()), 10) - hours * 60;
    return `${hours}h ${minutes}m`;
  }

  openTimesheet(timesheet: ITimesheet) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'timesheet-dialog';
    dialogConfig.data = {
      timesheet
    };
    this.dialog.open(TimesheetDetailComponent, dialogConfig);
  }
}
