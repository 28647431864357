<form class="timesheet-detail-form mb-20" [formGroup]="timesheetForm" *ngIf="data">
    <button [disabled]="timesheetForm.invalid" class="delete" *ngIf="timesheetId !== undefined && canDelete()" (click)="deleteTimesheet()" mat-dialog-close mat-button color="warn"><mat-icon>delete_forever</mat-icon>Delete</button>
    <h1 mat-dialog-title>Timesheet Detail</h1>
    <div mat-dialog-content *ngIf="user$ | async;">
        <!-- Timesheet ID: {{this.timesheetId}}<br> -->
        <!-- User -->
        <div class="row">
            <div class="column no-padding">
                <mat-form-field class="full-width">
                    <input matInput formControlName="user" placeholder="User" [matAutocomplete]="userAutoComplete">
                </mat-form-field>
                <mat-autocomplete #userAutoComplete="matAutocomplete" [displayWith]="displayUsername">
                    <mat-option *ngFor="let user of (filteredUsers$ | async)" [value]="user">{{user.firstName}} {{user.lastName}}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="timesheetForm.get('user').errors && timesheetForm.get('user').errors.invalidSelection">
                    Invalid User
                </mat-error>
            </div>
        </div>
        <!-- Job -->
        <div class="row">
            <div class="column no-padding">
                <mat-form-field class="full-width">
                    <input matInput formControlName="job" placeholder="Job" [matAutocomplete]="autoJob">
                </mat-form-field>
                <mat-autocomplete #autoJob="matAutocomplete" [displayWith]="displayJobName" (optionSelected)="populateServices($event.option.value)">
                    <mat-option *ngFor="let job of (filteredJobs$ | async)" [value]="job">{{job.name}}</mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="timesheetForm.get('job').errors && timesheetForm.get('job').errors.invalidSelection">
                    Invalid Job
                </mat-error>
            </div>
        </div>
        <!-- Job Service -->
        <div class="row">
            <div class="column no-padding">
                <mat-form-field>
                    <mat-select formControlName="selectedService" [compareWith]="compareIds">
                        <mat-option *ngFor="let service of services" [value]="service">
                            {{service.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <!-- ClockIn/ClockOut -->
        <div class="row">
            <div class="column no-padding">
                <mat-form-field>
                    <mat-placeholder>Clock In</mat-placeholder>
                    <mat-datetimepicker-toggle [for]="clockInPicker" matSuffix></mat-datetimepicker-toggle>
                    <mat-datetimepicker #clockInPicker type="datetime" mode="portrait" startView="clock" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                    <input matInput formControlName="clockIn" [matDatetimepicker]="clockInPicker" required autocomplete="false">
                    <mat-error *ngIf="timesheetForm.get('clockIn').errors && timesheetForm.get('clockIn').errors.invalidSelection">
                        ClockIn Must be before ClockOut
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="column no-padding">
                <mat-form-field>
                    <mat-placeholder>Clock Out</mat-placeholder>
                    <mat-datetimepicker-toggle [for]="clockOutPicker" matSuffix></mat-datetimepicker-toggle>
                    <mat-datetimepicker #clockOutPicker type="datetime" mode="portrait" startView="clock" openOnFocus="true" timeInterval="5"></mat-datetimepicker>
                    <input matInput formControlName="clockOut" [matDatetimepicker]="clockOutPicker" autocomplete="false">
                    <mat-error *ngIf="timesheetForm.get('clockOut').errors && timesheetForm.get('clockOut').errors.invalidSelection">
                        ClockOut Must be after ClockIn
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <!-- Notes -->
        <div class="row">
            <div class="column no-padding">
                <mat-form-field>
                    <textarea matInput formControlName="notes" placeholder="Notes"></textarea>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="column no-padding">
                <mat-form-field>
                    <textarea matInput formControlName="reasonForChange" placeholder="Reason for Change" cdkFocusInitial></textarea>
                </mat-form-field>
            </div>
        </div>
        <!-- <pre>FormData {{ timesheetForm.value | json }}</pre> -->

    </div>
    <div mat-dialog-actions>
        <button mat-dialog-close mat-raised-button>Cancel</button>
        <button [disabled]="timesheetForm.invalid" *ngIf="canSave()" (click)="updateTimesheet('Submitted')" mat-dialog-close mat-raised-button color="primary"><mat-icon>done</mat-icon> Save</button>
        <button [disabled]="timesheetForm.invalid" *ngIf="canReject()" (click)="updateTimesheet('Rejected')" mat-dialog-close mat-raised-button color="warn"><mat-icon>thumb_down</mat-icon> Reject</button>
        <span class="spacer"></span>
        <button [disabled]="timesheetForm.invalid" *ngIf="canApprove()" (click)="updateTimesheet('Approved')" class="approve" mat-dialog-close mat-raised-button><mat-icon>thumb_up</mat-icon> Approve</button>
    </div>
</form>