<div class="mat-elevation-z8">
    <table class="full-width" mat-table [dataSource]=yellowsheets matSort>

        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Date </th>
            <td mat-cell *matCellDef="let yellowsheet"><a routerLink="/jobdetail/{{jobId}}/yellowsheet/{{yellowsheet.id}}">{{yellowsheet.date| date:'MM/dd/yyyy'}}</a></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]"></mat-paginator>
</div>
