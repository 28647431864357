import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl, ValidationErrors } from '@angular/forms';
import { ITimesheet } from 'src/app/models/interfaces/timesheet.interface';
import { JobService } from 'src/app/services/jobs/job.service';
import { UserService } from 'src/app/services/users/user.service';
import { Observable } from 'rxjs';
import { User } from 'src/app/models/classes/user.class';
import { Job } from 'src/app/models/classes/job.class';
import { TimesheetService } from 'src/app/services/timesheets/timesheet.service';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { startWith, map, tap } from 'rxjs/operators';
import { Service } from 'src/app/models/classes/service.class';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { MatDialog } from '@angular/material';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { TimesheetStatus } from 'src/app/models/enums/timesheet.enum';

@Component({
    selector: 'app-timesheet-detail',
    templateUrl: './timesheet-detail.component.html',
    styleUrls: ['./timesheet-detail.component.scss']
})
export class TimesheetDetailComponent implements OnInit {

    public timesheetForm: FormGroup;
    public user$: Observable<User>;
    public yellowsheetId: string;
    public timesheetId: string;
    public timesheet: ITimesheet;
    public users: User[];
    public filteredUsers$: Observable<User[]>;
    public jobs: Job[];
    public filteredJobs$: Observable<Job[]>;
    public isAdmin: boolean;
    public services: Service[];
    private format = 'MM-dd-yyyy hh:mm a';
    public dtSettings = {
        bigBanner: true,
        timePicker: true,
        format: this.format,
        defaultOpen: false,
        closeOnSelect: true,
        rangepicker: false
    };


    constructor(
        private fb: FormBuilder,
        private router: Router,
        private jobService: JobService,
        public authService: AuthService,
        private userService: UserService,
        private timesheetService: TimesheetService,
        public dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) { }

    ngOnInit() {
        this.getUserRole();
        this.getUsers();
        this.getActiveJobs();
        this.buildForm();
    }

    /*
    * Handle the auto complete User search function
    */
    public getUsers() {
        this.userService.getActiveUsers().subscribe((users: User[]) => {
            this.users = users;
            this.filteredUsers$ = this.timesheetForm.controls.user.valueChanges
                .pipe(
                    startWith<string | User>(''),
                    map(value => typeof value === 'string' ? value : value.firstName),
                    map(name => name ? this._filterUsers(name) : this.users.slice())
                );
        });
    }

    private _filterUsers(name: string): User[] {
        const filterValue = name.toLowerCase();
        return this.users.filter(user => user.firstName.toLowerCase().indexOf(filterValue) === 0);
    }

    /*
    * Handle the auto complete Job search function
    */
    public getActiveJobs() {
        this.jobService.getActiveJobs().subscribe((jobs: Job[]) => {
            this.jobs = jobs;
            this.filteredJobs$ = this.timesheetForm.controls.job.valueChanges
                .pipe(
                    startWith<string | Job>(''),
                    map(value => typeof value === 'string' ? value : value.name),
                    map(name => name ? this._filterJobs(name) : this.jobs.slice())
                );
        });
    }

    private _filterJobs(name: string): Job[] {
        const filterValue = name.toLowerCase();
        return this.jobs.filter(job => job.name.toLowerCase().indexOf(filterValue) === 0);
    }

    // Populate Job Services when the Job Changes
    public populateServices(job: Job) {
        this.services = job.services;
    }

    // Build up our Dynamic Form
    public buildForm() {
        const datePipe = new DatePipe(navigator.language);
        this.timesheet = this.data.timesheet;
        this.timesheetId = this.timesheet.id;
        this.yellowsheetId = this.timesheet.yellowsheetId;

        // We need to lookup the Job since we have limited information about the job (i.e. jobId, jobName)
        this.jobService.getJob(this.timesheet.job.id).subscribe((job: Job) => {
            // set the inital values to the Yellowsheets Job values
            (this.timesheetForm.get('job') as FormControl).setValue(job);
            this.services = job.services;
        });

        // Setup our Time and User info (if we have them)
        const user = this.timesheet.user ? this.timesheet.user : '';
        const selectedService = this.timesheet.selectedService ? this.timesheet.selectedService : '';
        // Since we might be passing in our
        const clockIn = this.timesheet.clockIn ? this.timesheet.clockIn.toDate() : new Date();
        const clockOut = this.timesheet.clockOut ? this.timesheet.clockOut.toDate() : new Date();
        const notes = this.timesheet.notes ? this.timesheet.notes : '';

        // Push our values to the form
        this.timesheetForm = this.fb.group({
            user: [user, [Validators.required], this.autoCompleteValidator.bind(this)],
            job: ['', [Validators.required], this.autoCompleteValidator.bind(this)],
            selectedService: [selectedService, Validators.required],
            clockIn: [clockIn, [Validators.required], this.checkClockInClockOutDate.bind(this)],
            clockOut: [clockOut, [Validators.required], this.checkClockInClockOutDate.bind(this)],
            notes: [notes],
            reasonForChange: ''
          });
    }

    autoCompleteValidator(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>  {
        const selection: any = control.value;
        return of(typeof selection !== 'string' ? null : {invalidSelection: true});
    }

    checkClockInClockOutDate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null>  {
        if (this.timesheetForm) {
            const clockIn = this.timesheetForm.controls.clockIn;
            const clockOut = this.timesheetForm.controls.clockOut;

            // Clear out any previous errors because the newly applied clockIn or clockOut value may be valid for both date values
            if (clockIn.errors && clockIn.errors.invalidSelection !== null) {
                clockIn.setErrors(null);
            }
            if (clockOut.errors && clockOut.errors.invalidSelection !== null) {
                clockOut.setErrors(null);
            }

            const validClockIn: boolean = clockIn.value < clockOut.value;

            return of(validClockIn ? null : {invalidSelection: true});
        } else {
            return of(null);
        }
    }

    // Handle option values for users
    public displayUsername(user: User) {
        if (user) { return user.firstName + ' ' + user.lastName; }
    }

    // Handle option values for Jobs
    public displayJobName(job: Job) {
        if (job) { return job.name; }
    }

    // Helper to select the appropriate service
    public compareIds(id1: any, id2: any): boolean {
        const a1 = id1.id;
        const a2 = id2.id;
        return a1 === a2;
    }

    // Save our timesheet
    public updateTimesheet(status: string) {
        // Get our values from the form, add our yellowsheet and timestamp (clockIn/clockOut) fields
        const timesheet = this.timesheetForm.value;
        timesheet.yellowsheetId = this.yellowsheetId;
        timesheet.id = this.timesheetId;

        // We need to convert our time into timestamps
        const clockIn = new Date(this.timesheetForm.get('clockIn').value);
        timesheet.clockIn = firebase.firestore.Timestamp.fromDate(clockIn);

        const clockOut = new Date(this.timesheetForm.get('clockOut').value);
        timesheet.clockOut = firebase.firestore.Timestamp.fromDate(clockOut);

        // Only update the status if we are approving or rejecting
        if (status !== '') {
            timesheet.status = status;
        }

        // Get our simplified Job object
        delete timesheet.job.addressLine1;
        delete timesheet.job.addressLine2;
        delete timesheet.job.city;
        delete timesheet.job.state;
        delete timesheet.job.zip;
        delete timesheet.job.services;
        delete timesheet.job.foreman;
        delete timesheet.job.materials;
        delete timesheet.job.materialsConsumed;
        delete timesheet.job.equipment;
        delete timesheet.job.equipmentConsumed;
        delete timesheet.job.updatedAt;
        delete timesheet.job.createdAt;
        delete timesheet.job.plantingHours;
        delete timesheet.job.sitePrepHours;
        delete timesheet.job.masonaryHours;

        // update or create our timesheet record
        this.timesheetService.updateTimesheet(timesheet)
        .then(result => {
            console.log(`updateTimesheet: Successfully updated timesheet ${timesheet.id}`);
        })
        .catch(error => {
            console.log(`!!! ERROR: updateTimesheet failed: ${error}`);
        });
    }

    getUserRole() {
        this.user$ = this.authService.user$.pipe(
            tap(user => {
                this.isAdmin = this.authService.isAdmin(user);
            }));
    }

    public canDelete(): boolean {
        return this.isAdmin && !(this.timesheet.status === TimesheetStatus.APPROVED || this.timesheet.status === TimesheetStatus.PROCESSED);
    }

    public canSave(): boolean {
      return !(this.timesheet.status === TimesheetStatus.APPROVED || this.timesheet.status === TimesheetStatus.PROCESSED);
    }

    public canReject(): boolean {
        return this.timesheet.id !== undefined && this.timesheet.status === TimesheetStatus.APPROVED;
    }

    public canApprove(): boolean {
        return this.timesheet.id !== undefined && this.timesheet.status === TimesheetStatus.SUBMITTED;
    }

    deleteTimesheet(): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '350px',
        data: 'Do you really want to delete this timesheet?'
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.timesheetService.deleteTimesheet(this.timesheetId)
          .then(deleteResult => {
            if (this.router.url.indexOf('/timesheets') > 0) {
                this.router.navigateByUrl('timesheets');
              }
           })
          .catch(error => {
              console.log(`!!! ERROR: deleteTimesheet failed: ${error}`);
          });
        }
      });
    }

}
