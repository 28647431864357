import { Component, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { JobService } from 'src/app/services/jobs/job.service';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';
import { Job } from 'src/app/models/classes/job.class';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss']
})

export class JobListComponent implements AfterViewInit {

  displayedColumns: string[] = ['isActive', 'name', 'customerName', 'addressLine1', 'city', 'state', 'foreman'];
  dataSource: MatTableDataSource<Job>;
  searchText: string = null;
  showAllJobs: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private jobService: JobService
  ) { }

  ngAfterViewInit() {
    this.getJobs();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getJobs() {
    this.jobService.getJobs(this.showAllJobs).subscribe(jobs => {
      this.dataSource = new MatTableDataSource(jobs);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
