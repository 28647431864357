import { UserService } from '../../../services/users/user.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/classes/user.class';
import {  Router } from '@angular/router';
import { Observable, Subscription, of, from } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { pipeFromArray } from 'rxjs/internal/util/pipe';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {

  public newUserForm: FormGroup;
  public subs: Subscription[] = [];
  public user$: Observable<User>;
  public userId: string;
  public roles: Observable<string[]> = of(new Array('User', 'Foreman', 'Admin'));

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.newUserForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
      firstName: ['', Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+')],
      lastName: ['', Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]+')],
      role: ['User', Validators.required]
    });
    this.getUser();
  }

  getUser() {
    this.user$ = this.user$ = this.userService
      .getUser(this.userId)
      .pipe(tap(user => this.newUserForm.patchValue(user)));
  }

  createUser() {
      this.userService.createUser(this.newUserForm.value)
      .then(() => {
        this.router.navigateByUrl('users');
      });
  }
}
