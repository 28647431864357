import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../../models/classes/user.class';
import { AngularFirestore } from '@angular/fire/firestore';
import { tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user$: Observable<User>;

  constructor(
    private router: Router,
    private fireauth: AngularFireAuth,
    private db: AngularFirestore
  ) {
     //// Get auth data, then get firestore user document || null
     this.user$ = this.fireauth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
     );
  }

  loginUser(email: string, password: string): void {
    this.fireauth.auth.signInWithEmailAndPassword(email, password).then(() => {
      this.router.navigate(['/']);
    });
  }

  resetPassword(email: string): Promise<void> {
    return this.fireauth.auth.sendPasswordResetEmail(email);
  }

  logoutUser(): void {
    this.fireauth.auth.signOut().then(() => {
      this.router.navigate(['/login']);
    });
  }

  isAdmin(user: User): boolean {
    const allowed = ['Admin'];
    return this.checkAuthorization(user, allowed);
  }

  isForeman(user: User): boolean {
    const allowed = ['Foreman'];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) {
      return false;
    }
    for (const role of allowedRoles) {
      if (user.role === role) {
        return true;
      }
    }
    return false;
  }

  // Returns current user data
  get currentUserId(): string {
    return this.fireauth.auth.currentUser.uid;
  }

  get currentUser(): Observable<firebase.User> {
    return this.fireauth.authState;
  }

  get authenticated(): boolean {
    return this.fireauth.authState !== null;
  }
}
