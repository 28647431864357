import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { Timesheet } from 'src/app/models/classes/timesheet.class';
import { ITimesheet } from 'src/app/models/interfaces/timesheet.interface';
import { TimesheetService } from 'src/app/services/timesheets/timesheet.service';
import { GoogleMapComponent } from '../../google-map/google-map.component';
import { TimesheetDetailComponent } from '../timesheet-detail/timesheet-detail.component';


@Component({
  selector: 'app-whos-working',
  templateUrl: './whos-working.component.html',
  styleUrls: ['./whos-working.component.sass']
})
export class WhosWorkingComponent implements AfterViewInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  timesheets: MatTableDataSource<ITimesheet>;

  displayedColumns: string[] = ['user',
  'jobName',
  'selectedService',
  'date',
  'clockIn'];

  constructor(    
    private timesheetService: TimesheetService,
    private dialog: MatDialog,
  ) {
  }

  ngAfterViewInit() {
    this.getWhosWorking();
  }

  applyFilter(filterValue: string) {
    this.timesheets.filter = filterValue.trim().toLowerCase();
  }

  getWhosWorking() {
    this.timesheetService.getWhosWorking().subscribe(timesheets => {

      this.timesheets = new MatTableDataSource(timesheets);
      this.timesheets.sort = this.sort;
      this.timesheets.paginator = this.paginator;

      /* configure filter */
      this.timesheets.filterPredicate = (timesheet: Timesheet, filterValue: string) => {
        return timesheet.user.firstName.trim().toLowerCase().indexOf(filterValue) !== -1
          || timesheet.user.lastName.trim().toLowerCase().indexOf(filterValue) !== -1;
      };
    });
  }
  
  openTimesheet(timesheet: ITimesheet) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      timesheet
    };
    this.dialog.open(TimesheetDetailComponent, dialogConfig);
  }

  openMap(timesheet: ITimesheet) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      type : 'TIMESHEET',
      timesheet
    };
    this.dialog.open(GoogleMapComponent, dialogConfig);
  }

}
