import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Service } from 'src/app/models/classes/service.class';
import { FirestoreService } from '../firestore/firestore.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(
    public db: FirestoreService,
  ) { }

  getServices(): Observable<Service[]> {
    return this.db.colWithIds$('services', ref => ref.where('status', '==', 'Active').orderBy('name', 'asc'));
  }
}
