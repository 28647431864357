<form class="material-form mb-20" [formGroup]="equipmentForm">
    <ol class="breadcrumb">
        <li><a [routerLink]="['/equipment']">Equipment</a></li>
        <li class="active">{{equipmentName}}</li>
    </ol>
    <!-- Row -->
    <div class="row">
        <!-- Left Column -->
        <div class="column">
            <!-- Customer Name -->
            <mat-form-field>
                <input matInput formControlName="name" placeholder="Equipment Name" />
            </mat-form-field>
            <div class="row button-row">
                <button routerLink="/equipment" mat-raised-button color="secondary">Cancel</button>
                <button type="submit" [disabled]="equipmentForm.invalid" (click)="updateEquipment()" mat-raised-button color="primary"><mat-icon>done</mat-icon> Save</button>
            </div>
        </div>
    </div>
    <!--
      <pre>FormData {{ equipmentForm.value | json }}</pre>
    -->
</form>