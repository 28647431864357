import { IYellowsheet } from '../../models/interfaces/yellowsheet.interface';
import { Injectable } from '@angular/core';
import { FirestoreService } from '../firestore/firestore.service';
import { Observable } from 'rxjs';
import { Yellowsheet } from 'src/app/models/classes/yellowsheets.class';

@Injectable({
  providedIn: 'root'
})
export class YellowsheetService {

  constructor(
    public db: FirestoreService,
  ) {
  }

  getYellowsheets(jobId: string): Observable<Yellowsheet[]> {
    if (jobId !== undefined ) {
      return this.db.colWithIds$('yellowsheets', ref => ref.where('jobId', '==', jobId).orderBy('date', 'desc'));
    } else {
      return this.db.colWithIds$('yellowsheets');
    }
  }

  getYellowsheet(yellowsheetId: string): Observable<Yellowsheet>  {
    return this.db.doc$('yellowsheets/' + yellowsheetId);
  }

  createYellowsheet(yellowsheet: Yellowsheet): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // Let's see if we have an existing yellowsheet first
      this.db.colWithIds$<IYellowsheet>('yellowsheets', ref => ref.where('jobId', '==', yellowsheet.jobId)
        .where('date', '==', yellowsheet.date).limit(1))
          .subscribe((result: IYellowsheet[]) => {
            if (result.length > 0) {
              // Return the id of the first yellowsheet, there should be the only returned
              resolve(result[0].id);
            } else {
              // We need to create one and return the id
              const yellowsheetId = this.db.createId();
              this.db.upsert('yellowsheets/' + yellowsheetId, yellowsheet).then(() => {
                resolve(yellowsheetId);
              });
            }
          });
    });
  }

  async updateYellowsheet(yellowsheetId: string, yellowsheet: Yellowsheet): Promise<void> {
    return this.db.upsert('yellowsheets/' + yellowsheetId, yellowsheet);
  }

  deleteYellowsheet(yellowsheetId: string) {
    this.db.doc('yellowsheets/' + yellowsheetId).delete();
  }
}
