import { IEquipment } from './../../../models/interfaces/equipment.interface';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { EquipmentService } from 'src/app/services/equipment/equipment.service';


@Component({
  selector: 'app-equipment-detail',
  templateUrl: './equipment-detail.component.html',
  styleUrls: ['./equipment-detail.component.scss']
})

export class EquipmentDetailComponent implements OnInit, OnDestroy {

  public equipmentForm: FormGroup;
  public subs: Subscription[] = [];
  public equipment: IEquipment;
  public equipmentId: string;
  public equipmentName: string;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private equipmentService: EquipmentService
  ) {}

  ngOnInit() {
    this.equipmentForm = this.fb.group({
      name: ['', Validators.required],
    });
    this.getEquipmentDetail();
  }

  getEquipmentDetail() {
    this.subs.push(
      this.activeRoute.params.subscribe(params => {
        this.equipmentId = params.equipmentId;
        this.equipmentService
          .getEquipmentDetail(this.equipmentId).subscribe((material: IEquipment) => {
              this.equipmentForm.patchValue(material);
              this.equipmentName = material.name;
          });
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  updateEquipment() {
      this.equipmentService.updateEquipment(this.equipmentId, this.equipmentForm.value)
      .then(() => {
        this.router.navigateByUrl('equipment');
      });
  }

}
