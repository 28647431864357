import { UserService } from '../../../services/users/user.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { User } from 'src/app/models/classes/user.class';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})

export class UserDetailComponent implements OnInit, OnDestroy {
  public userForm: FormGroup;
  public changePasswordForm: FormGroup;
  public subs: Subscription[] = [];
  public user$: Observable<User>;
  public userId: string;
  public fullName: string;
  public roles: Observable<string[]> = of(new Array('User', 'Foreman', 'Admin'));
  public wageTypes: any[] = [];
  public loading = false;

  constructor(
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  /* removed for now, put back after users are cleaned up.
    regularTime : ['', Validators.required],
    overTime : ['', Validators.required]
  */
  ngOnInit() {
    this.userForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      qbListId: '',
      isActive: [false, Validators.required],
      role: ['User', Validators.required],
      regularTime : [''],
      overTime : [''],
      holidayTime : [''],
      funeralTime : [''],
      sickTime : [''],
      vacationTime : [''],
    });
    this.changePasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.passwordMatchValidator
    });
    this.getUser();
  }

  getUser() {
    this.subs.push(
      this.activeRoute.params.subscribe(params => {
        this.userId = params.userId;
        this.user$ = this.userService
          .getUser(this.userId)
          .pipe(tap(user => {
            this.userForm.patchValue(user),
            this.fullName = user.firstName + ' ' + user.lastName;
            const wageTypes = new Array();
            if (user.wageTypes) {
              user.wageTypes.forEach((wt: any) => {
                const wageType = {name : wt.name, qbListId : wt.qbListId };
                wageTypes.push(wageType);
              });
            }
            this.wageTypes = wageTypes;
          }));
      })
    );
  }

  // Helper to select the appropriate service
  public compareWages(wage1: any, wage2: any): boolean {
    const a1 = wage1.qbListId;
    const a2 = wage2.qbListId;
    return a1 === a2;
  }

  passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ mustMatch: true });
    }
  }


  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  updateUser() {
      this.loading = true;
      this.userService.updateUser(this.userId, this.userForm.value)
      .then(() => {
        this.loading = false;
        this.router.navigateByUrl('users');
      });
  }

  changePassword() {
    const password = this.changePasswordForm.controls.password.value;
    this.userService.changeUserPassword(this.userId, password)
      .then(() => {
        this.router.navigateByUrl('users');
      });
  }
}
