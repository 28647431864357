import { Component, Inject, OnInit } from '@angular/core';
import { ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { } from 'googlemaps';
import { IJob } from 'src/app/models/interfaces/job.interface';
import { ITimesheet } from 'src/app/models/interfaces/timesheet.interface';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.sass']
})

export class GoogleMapComponent implements OnInit {

  public timesheet: ITimesheet;
  private job: IJob;

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    let jobLat = 0;
    let jobLong = 0;

    // Depending on the type we need to set the job from different objects.
    if (this.data.type == 'TIMESHEET') {
      this.timesheet = this.data.timesheet;
      this.job = this.timesheet.job;
    }
    else {
      this.job = this.data.job;
    }

    jobLat = this.job.latitude;
    jobLong = this.job.longitude;

    // Create the job map
    const mapProperties = {
      center: new google.maps.LatLng(jobLat, jobLong),
      zoom: 16,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
    this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

    // Place the job marker
    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(jobLat, jobLong),
      map: this.map
    });
    marker.setMap(this.map);

    // Add a boundry only for timesheets
    if (this.data.type == 'TIMESHEET') {

      const timesheetLat = this.timesheet.clockInLongitude;
      const timesheetLong = this.timesheet.clockInLatitude;

      const svgMarker = {
        path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
        fillColor: "blue",
        fillOpacity: 0.6,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
        anchor: new google.maps.Point(0, 20),
      };

      const marker = new google.maps.Marker({
        position: new google.maps.LatLng(timesheetLat, timesheetLong),
        map: this.map,
        icon: svgMarker
      });
      marker.setMap(this.map);


      const circle = new google.maps.Circle({
        center: new google.maps.LatLng(this.job.latitude, this.job.longitude),
        radius: 100,
        strokeColor: '#ededed',
        fillColor: '#777777'
      })
      circle.setMap(this.map);
    }

  }

}
