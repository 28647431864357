import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/users/user.service';
import { IUser } from 'src/app/models/interfaces/user.interface';
import { MatTableDataSource, MatSort, MatPaginator } from '@angular/material';


@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements AfterViewInit {

  displayedColumns: string[] = ['isActive', 'email', 'firstName', 'lastName', 'role'];
  dataSource: MatTableDataSource<IUser>;
  showAllUsers: boolean = false;
  searchText: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService
  ) { }

  ngAfterViewInit() {
    this.getUsers();
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  getUsers() {  
    this.userService.getUsers(this.showAllUsers).subscribe(users => {
      this.dataSource = new MatTableDataSource(users);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }
}
