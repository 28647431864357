import { JobClass } from '../classes/job-class.class';
import { IJob } from '../interfaces/job.interface';
import { Material } from '../classes/material.class';
import { Equipment } from '../classes/equipment.class';
import { Service } from '../classes/service.class';
import { User } from '../classes/user.class';

export class Job implements IJob {
    id: string;
    name: string;
    customerName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    zip: string;
    sitePrepHours: number;
    masonaryHours: number;
    plantingHours: number;
    sitePrepHoursLogged: any;
    masonaryHoursLogged: any;
    plantingHoursLogged: any;
    services: Service[];
    materials: Material[];
    equipment: Equipment[];
    materialsConsumed: Material[];
    equipmentConsumed: Equipment[];
    jobClass: JobClass;
    foreman: User;
    createdAt: Date;
    updatedAt: Date;
    latitude: number;
    longitude: number;

    constructor() {
        this.id = null;
        this.services = new Array<Service>();
        this.equipment = new Array<Equipment>();
        this.materials = new Array<Material>();
        this.equipmentConsumed = new Array<Equipment>();
        this.materialsConsumed = new Array<Material>();
    }

}
